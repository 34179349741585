import React from 'react';
import {SearchProvider} from "../../../context";
import {Dashboard as DashboardTemplate} from '../../Templates';

const Dashboard = () => {
    return (
        <SearchProvider>
            <DashboardTemplate/>
        </SearchProvider>
    )
};

export default Dashboard;
