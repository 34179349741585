import * as React from 'react';
import {ArticlesCard, CardList, Footer, Jumbotron} from "../../Organisms";
import ProductList from "./ProductList";
import {colors} from "../../../config/theme";
import {Button, Col, Container, Row} from "reactstrap";
import styled from "styled-components";
import bannerImg from "../../../assets/img/banner2.png";
import {Flex} from "../../Atoms";
import {Header} from "../../Organisms/Homepage";
import products from "../../../config/products";
import {InEnergy, InHealthcare, PrService, SitaService, Webnoviny} from "../../Organisms/Dashboard";
import {ShowMore} from "../../Molecules";

const StyledHeaderWrapper = styled(Flex)`
    background: linear-gradient(180deg, rgba(0, 0, 0, .3) 0%, rgba(0, 0, 0, 0) 40%);
`;

const StyledHeader = styled(Flex)`
    background: url(${bannerImg});
    background-size: cover;
    color: ${colors.white};
    // height: 613px;
    flex: 1;
    display: flex;
    // margin-bottom: -150px;
`;

const Homepage = () => (
    <>
        <StyledHeader flexDirection="column">
            <StyledHeaderWrapper  flex="1 0 auto"  flexDirection="column">
                <Flex flex={1} flexDirection="column">
                    <Header/>

                    <Container>
                        <Flex>
                            <Flex className="pt-5 mt-5" flexDirection="column" justifyContent="center" alignItems="start" md={4}>
                                <h1 className="font-weight-light">AGENTÚRNE SPRAVODAJSTVO</h1>
                                <p>Dôveryhodné, overené správy v reálnom čase. <br/>
                                Tím reportérov SITA sedem dní v týždni prináša informácie z kľúčového diania <br/>
                                na domácej aj svetovej scéne so zameraním na ekonomiku, politiku a šport.<br/>
                                </p>
                                <Button tag="a" href="https://www.sita.sk/agenturne-spravodajstvo/" color="froly" className="text-white">VIAC INFO</Button>
                            </Flex>
                        </Flex>
                    </Container>
                </Flex>
            </StyledHeaderWrapper>
        </StyledHeader>

        {/*<Container>*/}
        {/*    <CardList items={products.map(product => ({*/}
        {/*        ...product,*/}
        {/*        backgroundColor: product.color,*/}
        {/*        color: colors.white*/}
        {/*    }))}/>*/}
        {/*    <Row>*/}
        {/*        <Col md={6}>*/}
        {/*            <SitaService/>*/}
        {/*        </Col>*/}
        {/*        <Col md={6}>*/}
        {/*            <Webnoviny/>*/}
        {/*        </Col>*/}
        {/*    </Row>*/}
        {/*    <Row>*/}
        {/*        <Col md={4}>*/}
        {/*            <InEnergy/>*/}
        {/*        </Col>*/}
        {/*        <Col md={4}>*/}
        {/*            <InHealthcare/>*/}
        {/*        </Col>*/}
        {/*        <Col md={4}>*/}
        {/*            <PrService/>*/}
        {/*        </Col>*/}
        {/*    </Row>*/}
        {/*</Container>*/}
        {/*<Jumbotron color={colors.tradewind} secondaryColor={colors.hippieBlue}/>*/}
        {/*<ProductList/>*/}
        {/*<Jumbotron color={colors.yellowOrange} secondaryColor={colors.fireBush}/>*/}
        <Footer/>
    </>
)

export default Homepage;
