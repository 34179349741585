import React from 'react';
import styled from 'styled-components';
import {Flex} from "../Atoms";
import {colors} from "../../config/theme";
import {Container} from "reactstrap";

const StyledContainer = styled(Flex)`
    @media (min-width: 992px) {
        background: linear-gradient(90deg, ${props => props.reverse ? props.primaryColor : props.secondaryColor} 49%, ${props => props.reverse ? props.secondaryColor : props.primaryColor} 51%);
    }
`;

const StyledJumbotron = styled(Flex)`
    background-color: ${props => props.color} !important;
    color: ${colors.white};
`;

const StyledSecondaryCol = styled(Flex)`
    background-color: ${props => props.color} !important;
    // margin-left: -1000px;
    // padding-left: 1000px !important;
`;

const Jumbotron = ({color, secondaryColor, reverse = false, children1, children2, ...rest}) => (
    <StyledContainer flex={1} flexDirection="column" primaryColor={color} secondaryColor={secondaryColor} reverse={reverse} {...rest}>
        <Container className="px-0 px-lg-3">
            <StyledJumbotron color={color}>
                <Flex flexDirection="column" flex={{
                    lg: {flexDirection: reverse ? 'row-reverse' : 'row'}
                }}>
                    <StyledSecondaryCol color={secondaryColor} lg={4}>
                        <Flex className="p-6" flexDirection="column" alignItems="left">
                            {children1}
                        </Flex>
                    </StyledSecondaryCol>
                    <Flex lg={8} className="p-6 flex-column flex-lg-row">
                        {children2}
                    </Flex>
                </Flex>
            </StyledJumbotron>
        </Container>
    </StyledContainer>
);

export default Jumbotron;
