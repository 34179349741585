import {gql} from '@apollo/client';

export default gql`query Preview {
    preview {
        data {
            id
            title
            subtitle
            authors {
                nick
            }
            locale
            priority {
                name
            }
            date
            content
            categories {
                name
                description
                slug
                color
                parent {
                    name
                    description
                    slug
                    color
                }
            }
            tags {
                name
                slug
            }      
            companies {
                id
                name
            }
            persons
        }
        meta {
            offset
            limit
            count
        }
    }
}`
