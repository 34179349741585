import {gql} from '@apollo/client';

export default gql`query Client($id: Int!) {
    client(id: $id) {
        id
        name
        tags {
            id
            value
        }
    }
}`