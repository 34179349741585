//@flow

import * as React from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {withRouter} from "react-router-dom";

import {GET_CLIENT, UPDATE_ARTICLE} from "../queries";
import {Field, Form, Formik} from "formik";
import {ReactstrapFormikCheckbox} from "./index";
import {Button, FormGroup, Row, Col} from "reactstrap";

const ArticleEdit = ({article, match: {params}, onClose}) => {
    const [updateArticle] = useMutation(UPDATE_ARTICLE);
    const {loading, error, data} = useQuery(GET_CLIENT, {
        variables: {
            id: params.client
        }
    });

    if (loading) {
        return 'Loading ...';
    }

    if (error) {
        // console.log(error);
    }

    return (
        <Formik initialValues={{tags: article?.articlesTags?.map(articleTag => articleTag.tag.id) || []}}
                onSubmit={(values) => {
                    updateArticle({
                        variables: {
                            id: article.id,
                            ...values
                        }
                    })
                    onClose();
                }}>
            <Form>
                <FormGroup>
                    <legend>Tagy</legend>
                    <Row>
                        {data.client.tags.map(tag => <Col xs={6}>
                            <Field id={`tag-${tag.id}`} type="checkbox" name="tags" value={tag.id} component={ReactstrapFormikCheckbox} label={tag.value}/>
                        </Col>)}
                    </Row>
                </FormGroup>
                <Button type="submit">Uložiť</Button>
            </Form>
        </Formik>
    )
}

export default withRouter(ArticleEdit);
