import {colors} from "./theme";
import searchInImg from "../assets/img/search-in.png";
import agenturneSpravyImg from "../assets/img/agenturne-spravy.png";
import monitoringMediiImg from "../assets/img/monitoring-medii.png";
import audioImg from "../assets/img/audio.png";
import fotoImg from "../assets/img/foto.png";
import marketingoveSluzbyImg from "../assets/img/marketingove-sluzby.png";
import firemneInformacieImg from "../assets/img/firemne-informacie.png";
import odborneObsahovePortalyImg from "../assets/img/odborne-obsahove-portaly.png";
import webnovinyImg from "../assets/img/webnoviny.png";

const products = [
    {
        active: true,
        color: colors.lightningYellow,
        img: agenturneSpravyImg,
        title: 'SITA insider',
        content: 'Spravodajstvo s pridanou hodnotou.',
    },
    {
        active: true,
        color: colors.flamingo,
        img: fotoImg,
        title: 'Fotobanka',
        content: 'Najrozsiahlejší súbor spravodajských fotografií.',
    },
    {
        active: true,
        color: colors.froly,
        img: monitoringMediiImg,
        title: 'Mediabase',
        content: 'Triedený monitoring médií na kľúč.',
    },
    {
        active: true,
        color: colors.yellowOrange,
        img: searchInImg,
        title: 'SearchIn',
        content: 'Databáza monitorovaných zdrojov.',
    },
    {
        active: true,
        color: colors.scooter,
        img: marketingoveSluzbyImg,
        title: 'PR Služby',
        content: 'Tvorba a široká distribúcia natívnych a PR správ.'
    },
    {
        active: true,
        color: colors.crimson,
        img: firemneInformacieImg,
        title: 'Firemné dáta',
        content: 'Detailné informácie o firmách.'
    },
    {
        active: false,
        color: colors.jungleGreen,
        img: audioImg,
        title: 'Audio',
        content: ''
    },
    {
        active: false,
        color: colors.citron,
        img: odborneObsahovePortalyImg,
        title: 'Odborné obsahové portály',
        content: ''
    },
    {
        active: false,
        color: colors.tussock,
        img: webnovinyImg,
        title: 'Webnoviny',
        content: ''
    },
]

export default products;
