import * as React from "react";
import {Flex} from "../components/Atoms";

const services = [
    {
        title: 'EKONOMIKA',
        perex: 'Online databáza dostupná v reálnom čase cez internet. Servis vysiela denne od 6.30 do 18.00, podľa potreby aj dlhšie. V pracovné dni vysiela približne 100 správ denne, v sobotu vysiela iba pri výnimočných udalostiach, v nedeľu vydá od 9.30 do 15.30 v priemere vyše 30 správ. K dispozícii je archív od roku 1997.',
        content: (
            <Flex flex={{
                xs: {flexDirection: 'column'},
                md: {flexDirection: 'row'}
            }}>
                <Flex flexDirection="column">
                    <h5>TEMATICKÉ OKRUHY SPRÁV</h5>
                    <ul>
                        <li>ekonomická politika</li>
                        <li>ekonomická legislatíva</li>
                        <li>legislatívny proces</li>
                        <li>hospodárske ministerstvá a príslušné rezorty</li>
                        <li>podnikové informácie</li>
                        <li>hospodárske výsledky</li>
                        <li>štatistické informácie</li>
                        <li>sociálny a poistný systém</li>
                        <li>tripartita</li>
                        <li>podnikanie</li>
                        <li>privatizácia</li>
                        <li>vnútorný a zahraničný obchod</li>
                        <li>zdravotníctvo</li>
                        <li>energetika</li>
                        <li>telekomunikácie a informačné technológie</li>
                        <li>automobilizmus</li>
                        <li>turizmus</li>
                        <li>vodné a lesné hospodárstvo</li>
                        <li>pôdohospodárstvo a potravinárstvo</li>
                        <li>burzové komentáre</li>
                        <li>finančné komentáre</li>
                        <li>ECB, menová politika a menový vývoj</li>
                        <li>zahraničné firmy</li>
                        <li>svetové peňažné, tovarové a akciové trhy</li>
                    </ul>

                    <h5>ZÁKLADNÉ HOSPODÁRSKE ÚDAJE</h5>
                    <ul>
                        <li>Základné makroekonomické údaje SR</li>
                        <li>Ceny vybraných komodít na svetových trhoch</li>
                        <li>Informácia Centrálneho depozitára cenných papierov (CDCP)</li>
                        <li>Fixing úrokových sadzieb na trhu medzibankových depozít</li>
                    </ul>
                </Flex>

                <Flex flexDirection="column">
                    <h5>KURZOVÉ LÍSTKY</h5>
                    <ul>
                        <li>Kurzový lístok ECB</li>
                        <li>Kurzový lístok devíz vo vybraných bankách v SR</li>
                        <li>Kurzový lístok devíz vo vybraných bankách v SR na nasledujúci deň</li>
                        <li>Kurzový lístok valút vo vybraných bankách v SR</li>
                        <li>Kurzový lístok valút vo vybraných bankách v SR na nasledujúci deň</li>
                        <li>Najvýhodnejšie kurzy valút</li>
                        <li>Forwardové kurzy eura vo vybraných bankách v SR</li>
                        <li>Krížové kurzy vybraných mien</li>
                        <li>Kurzy mien reformných krajín</li>
                        <li>Zatváracie hodnoty devízového trhu</li>
                    </ul>

                    <h5>TRHY S CENNÝMI PAPIERMI</h5>
                    <ul>
                        <li>BCPB - sumár (denný a týždenný)</li>
                        <li>BCPB - sumár anonymných obchodov s akciami (denný a týždenný)</li>
                        <li>BCPB - sumár priamych obchodov s akciami (denný a týždenný)</li>
                        <li>BCPB - sumár anonymných obchodov s dlhopismi (denný a týždenný)</li>
                        <li>BCPB - sumár priamych obchodov s dlhopismi (denný a týždenný)</li>
                        <li>BCPB - vývoj indexov (denný a týždenný)</li>
                        <li>denný komentár k obchodovaniu na BCPB</li>
                        <li>týždenný komentár k obchodovaniu na slovenskom kapitálovom trhu</li>
                    </ul>

                    <h5>PREHĽADY OČAKÁVANÝCH UDALOSTÍ</h5>
                    <ul>
                        <li>prehľad udalostí očakávaných v ekonomike na aktuálny deň</li>
                        <li>prehľad očakávaných udalostí na nasledujúci deň resp. víkend</li>
                        <li>prehľad očakávaných valných zhromaždení</li>
                        <li>prehľad očakávaných veľtržných podujatí</li>
                        <li>týždenný prehľad očakávaných udalostí</li>
                        <li>oznam redakciám o mediálnej akcii, ktorá sa koná v aktuálny deň, po vydaní</li>
                        <li>prehľadu očakávaných udalostí na aktuálny deň</li>
                    </ul>

                    <h5>DOKUMENTY</h5>
                    <ul>
                        <li>prehľad najdôležitejších správ z domácej a svetovej ekonomiky z predchádzajúceho dňa</li>
                        <li>uplynulý týždeň v ekonomike SR (zhrnutie najdôležitejších udalostí v ekonomike SR za týždeň)</li>
                        <li>ekonomické body programu rokovania vlády</li>
                        <li>iné dôležité dokumenty</li>
                    </ul>

                </Flex>
            </Flex>
        )
}
];

export default services;
