import * as React from 'react';
import styled from 'styled-components';
import {Big, Flex} from "../Atoms";
import {colors} from "../../config/theme";
import {Button, Container} from "reactstrap";
import Basket from "../Atoms/Icons/Basket";
import {Jumbotron} from "../Molecules";

const StyledContainer = styled(Flex)`
    @media (min-width: 992px) {
        background: linear-gradient(90deg, ${props => props.secondaryColor} 49%, ${props => props.primaryColor} 51%);
    }
`;

const StyledJumbotron = styled(Flex)`
    background-color: ${props => props.color} !important;
    color: ${colors.white};
`;

const StyledSecondaryCol = styled(Flex)`
    background-color: ${props => props.color} !important;
    // margin-left: -1000px;
    // padding-left: 1000px !important;
`;

const StyledSecondaryTitle = styled.h1`
    font-weight: 300;
`

const StyledPrice = styled.h2`
    color: ${colors.black};
    font-weight: 300;
    
    span {
        font-size: 48px;
        font-weight: bold;
        
        sup {
            font-size: 24px;
        }
    }
`

const ProductJumbotron = ({color, secondaryColor, reverse = false}) => (
    <Jumbotron color={color} secondaryColor={secondaryColor} reverse={reverse} children1={
        <>
            <StyledSecondaryTitle>predplatné<br/><strong>čosi</strong></StyledSecondaryTitle>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,  quis nostrud exercitation  ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>
            <Button color="secondary">VIAC INFO</Button>
        </>
    } children2={
        <>
            <Flex lg={5} flexDirection="column">
                <Flex className="mb-5">
                    Textové spravodajstvo ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </Flex>
                <Flex className="mb-5">
                    Textové spravodajstvo ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </Flex>
                <Flex className="mb-5">
                    Textové spravodajstvo ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </Flex>
            </Flex>
            <Flex className="ps-6" lg={7} flexDirection="column" justifyContent="center" alignItems="center">
                <Flex className="mb-3">
                    <Button>1 servis</Button>
                    <Button color="secondary">2 servis</Button>
                    <Button color="secondary">3 servis</Button>
                    <Button color="secondary">4 servis</Button>
                </Flex>
                <Flex className="mb-3">
                    <Button>1 licencia</Button>
                    <Button color="secondary">multilicencia</Button>
                </Flex>
                <Flex className="mb-3">
                    <StyledPrice>cena bez DPH <Big>400<sup>&euro;</sup></Big> / rok</StyledPrice>
                </Flex>
                <Flex>
                    <Button><Basket size={16}/> Pridať do košíka</Button>
                </Flex>
            </Flex>
        </>
    }>
    </Jumbotron>
);

export default ProductJumbotron;
