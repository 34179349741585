import * as React from 'react';
import {Flex, FormikInput} from "../../Atoms";
import styled from 'styled-components';
import {colors} from "../../../config/theme";
import {Form} from "reactstrap";
import {Formik} from "formik";
import DatePickerInput from "../DatePickerInput";
import {useContext} from "react";
import {ArticlesContext} from "../../../context";
import {Container, Button} from "reactstrap";

const StyledSearch = styled(Flex)`
    background-color: ${colors.concrete};
`;

const Search = () => {
    const {filter: {filter, setFilter}} = useContext(ArticlesContext);

    return <StyledSearch alignItems="center" className="border-bottom">
        <Container>
            <Formik initialValues={{
                        query: filter.query,
                        date: filter.date
                    }}
                    onSubmit={values => setFilter({
                        ...filter,
                        ...values
                    })}
                    validate={values => null}
                    enableReinitialize={true}
            >
                {({handleSubmit, handleReset}) => <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <Flex flexWrap="wrap" className="pt-3 mx-n2">
                        <Flex flex="1 0 100%" className="mb-3 flex-md-fill px-2 mw-100">
                            <FormikInput name="query"/>
                        </Flex>
                        <Flex flex="1 0 auto" className="mb-3 flex-md-grow-0 px-2 mw-100">
                            <DatePickerInput name="date"/>
                        </Flex>
                        <Flex flex="1 0 auto" className="mb-3 flex-md-grow-0 px-2 mx-n2">
                            <Flex flex="1 0 auto" className="px-2">
                                <Button type="submit" color="primary" block={true}>Hľadaj</Button>
                            </Flex>
                            <Flex flex="1 0 auto" className="px-2">
                                <Button block={true} color="primary" outline={true}>Uložiť</Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Form>}
            </Formik>
        </Container>
    </StyledSearch>
};

export default Search;
