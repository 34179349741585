import * as React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Avatar, Big, Flex} from "../../Atoms";
import {colors} from "../../../config/theme";
import {Nav, NavItem, NavLink} from 'reactstrap';
import {Header as Navigation} from '../';
import QuickModal from "../../QuickModal";
import Help from "../../Pages/Help";
import {useState} from "react";

const StyledHeader = styled(Flex)`
    padding: 0 !important;
    background-color: ${colors.black} !important;
`;

const StyledNavItem = styled(NavItem).attrs({className: 'px-1'})`
    border-left: 1px solid ${colors.boulder}
`;

const StyledNavLink = styled(NavLink)`
    color: ${colors.white};
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
        text-decoration: underline !important;
    }
`

const StyledAvatarNavLink = styled(StyledNavLink).attrs({className: 'py-1 px-2'})``

const Header = ({actions}) => {
    const [isHelpOpen, setIsHelpOpen] = useState(false);

    return <StyledHeader flexDirection="column">
        <Navigation actions={actions}>
            <Flex flex={1} className="justify-content-between">
                <Nav navbar={true}>
                    <StyledNavItem>
                        <StyledNavLink tag={Link} to="/dashboard"><Big>o SITA</Big></StyledNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                        <StyledNavLink tag={Link} to="/logout"><Big>služby</Big></StyledNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                        <StyledNavLink tag={Link} to="/logout"><Big>kontakt</Big></StyledNavLink>
                    </StyledNavItem>
                </Nav>

                <Nav navbar={true}>
                    <StyledNavItem>
                        <StyledNavLink onClick={() => setIsHelpOpen(true)}>Help</StyledNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                        <StyledAvatarNavLink tag={Link} to="/logout">
                            <Avatar/>
                        </StyledAvatarNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                        <StyledNavLink tag={Link} to="/logout">Odhlásiť</StyledNavLink>
                    </StyledNavItem>
                </Nav>
            </Flex>
        </Navigation>
        {isHelpOpen && <QuickModal title="Nápoveda" size="xl" onClose={() => setIsHelpOpen(!isHelpOpen)}>
            <Help/>
        </QuickModal>}
    </StyledHeader>
}

export default Header;
