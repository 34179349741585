import React from 'react';
import {Input, InputGroup, InputGroupText} from "reactstrap";
import PropTypes from "prop-types";

const Search = ({onHelp, ...rest}) => (
    <InputGroup>
        <InputGroupText>
            Search
        </InputGroupText>
        <Input placeholder="Search ..." {...rest}/>
        <InputGroupText onClick={onHelp}>
            Help
        </InputGroupText>
    </InputGroup>
);

Search.propTypes = {
    onHelp: PropTypes.func.isRequired
}
Search.defaultProps = {
    onHelp: () => {}
}

export default Search;
