import React from 'react';
import {Input} from "reactstrap";

const FormikInput = (
    {
        field: {...fields},
        form: {touched, errors, ...rest},
        ...props
    }) => (
        <Input {...props} {...fields} invalid={Boolean(touched[fields.name] && errors[fields.name])}/>
);
export default FormikInput;
