import React, {useEffect, useState} from 'react';
import {Avatar, Em, Flex, Hr} from "../../Atoms";
import {ShowMore} from "../../Molecules";
import {WIKI_URL} from "../../../config/config";
import {Badge} from "reactstrap";
import classNames from "classnames";

const RelatedPerson = ({keyword}) => {
    const [person, setPerson] = useState([]);

    useEffect(() => {
        const url = WIKI_URL + '?' + (new URLSearchParams({
            action: 'query',
            origin: '*',
            prop: 'extracts',
            format: 'json',
            titles: keyword
        })).toString();

        fetch(url)
            .then(result => result.json())
            .then(result => {
                setPerson(result.query?.pages?.[Object.keys(result.query?.pages)[0]])
            })
    }, [keyword])

    return <div>
        <div className="mt-2">
            <Flex alignItems="center">
                <h3 className="d-flex align-items-center">
                    {person.title}
                    <Avatar/>
                </h3>
            </Flex>
            <div>
                <Em>Popis</Em>
                <div dangerouslySetInnerHTML={{__html: person.extract}}/>
            </div>
        </div>
        <Hr/>
        <a href={`https://sk.wikipedia.org/wiki/${person.title}`} target="_blank" rel="noreferrer">
            <ShowMore/>
        </a>
    </div>
}

const RelatedPersons = ({keywords}) => {
    const [active, setActive] = useState(keywords?.[0]);

    return (
        <Flex id="person-search" flexDirection="column" flex={1}>
            <Flex justifyContent="space-between" alignItems="center">
                <h2>Súvisiace osoby</h2>
                <Flex>
                    {keywords.map((keyword, index) => (
                        <Badge key={index}
                               className={classNames('ml-2', {'text-black': active !== keyword})}
                               color={active === keyword ? 'primary' : 'default'}
                               onClick={() => setActive(keyword)}
                        >{keyword}</Badge>
                    ))}
                </Flex>
            </Flex>
            <Hr/>
            {keywords.map((keyword, index) => (
                (active === keyword) && <RelatedPerson key={index} keyword={keyword}/>
            ))}
        </Flex>
    )
};

export default RelatedPersons;
