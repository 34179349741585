import {gql} from '@apollo/client';

export default gql`query Profile {
    profile {
        id
        nick
        favorites {
            id
        }
    }
}`