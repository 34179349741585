import React, {useContext} from 'react';
import ArticleHeader from "./ArticleHeader";
import styled from 'styled-components';
import ArticleActions from "./ArticleActions";
import {Flex} from "../../Atoms";
import ArticleToolbar from "./ArticleToolbar";
import RelatedSearch from "../../Pages/Dashboard/RelatedSearch";
import PhotoSearch from "../../Pages/Dashboard/PhotoSearch";
import RelatedPersons from "./RelatedPersons";
import RelatedCompanies from "./RelatedCompanies";
import Lock from "../Lock";
import {ArticlesContext, UserContext} from "../../../context";
import SearchInSearch from "../../Pages/Dashboard/SearchInSearch";
import PhotoProvider from "../../../context/Provider/PhotoProvider";

const StyledContent = styled.div`
    font-size: .9rem;
`;

const ScrollableArticle = styled(Flex)`
    overflow: auto;
`;

const ArticleDetail = ({id, title, content, date, service, authors, tags, persons, companies, setSelected}) => {
    const {user} = useContext(UserContext);
    const {filter: {filter}} = useContext(ArticlesContext);

    return (
        <PhotoProvider tags={tags} persons={persons} companies={companies}>
            <Flex flex={1} flexDirection="column">
                <ArticleToolbar companies={0 !== companies.length} persons={0 !== persons.length}/>
                <ScrollableArticle id="article-detail" flexDirection="column">
                    <Flex flexDirection="column" className="p-3" flexShrink={0}>
                        <h1 id="article" dangerouslySetInnerHTML={{__html: title}} className="mb-4"/>

                        <ArticleHeader date={date} authors={authors} tags={tags} service={service}/>

                        <ArticleActions id={id} title={title} content={content}/>

                        <StyledContent dangerouslySetInnerHTML={{__html: content}}/>

                        {/*<ArticlesCard title="Súvisiace správy z archívu Sita"/>*/}

                        <Flex flexDirection="column" className="mb-5">
                            <RelatedSearch
                                keywords={tags.map(tag => tag.name)}
                                persons={persons}
                                companies={companies.map(company => company.name)}
                                from={filter.date.from}
                                to={filter.date.to}
                                onMore={() => setSelected(null)}
                            />
                        </Flex>

                        <Lock locked={-1 === user.meta.aud.indexOf('search')} className="mb-5">
                            <SearchInSearch
                                keywords={tags.map(tag => tag.name)}
                                // keywords={title.replace(':', '').split(' ')}
                                persons={persons}
                                companies={companies.map(company => company.name)}
                                from={filter.date.from}
                                to={filter.date.to}
                            />
                        </Lock>

                        <Lock locked={-1 === user.meta.aud.indexOf('photo')} className="mb-5">
                            <PhotoSearch
                                keywords={[
                                    ...tags.map(tag => tag.name),
                                    ...persons,
                                    ...companies.map(company => company.name)
                                ]}
                                from={filter.date.from}
                                to={filter.date.to}
                            />
                        </Lock>

                        {0 !== persons.length && <Lock locked={-1 === user.meta.aud.indexOf('orsr')} className="mb-5">
                            <RelatedPersons keywords={persons}/>
                        </Lock>}

                        {0 !== companies.length && <Lock locked={-1 === user.meta.aud.indexOf('orsr')} className="mb-5">
                            <RelatedCompanies companies={companies}/>
                        </Lock>}
                    </Flex>

                </ScrollableArticle>
            </Flex>
        </PhotoProvider>
    )
}

export default ArticleDetail;
