import * as React from 'react';
import {useState} from "react";
import {Flex} from "../Atoms";
import {Nav, NavItem, NavLink} from "reactstrap";
import styled from "styled-components";

const StyledNavLink = styled(NavLink)`
    cursor: pointer;
`;

const StyledImg = styled.img`
    width: 100%;
`;

const Help = () => {
    const [tab, setTab] = useState('login');

    return <Flex>
        <Flex as={Nav} flexDirection="column">
            <NavItem><StyledNavLink onClick={() => setTab('login')}>Prihlásenie</StyledNavLink></NavItem>
            <NavItem><StyledNavLink onClick={() => setTab('basics')}>Základná obrazovka</StyledNavLink></NavItem>
            <NavItem><StyledNavLink onClick={() => setTab('article-list')}>Výpis správ</StyledNavLink></NavItem>
            <NavItem><StyledNavLink onClick={() => setTab('navigation')}>Navigačné prvky výpisu správ</StyledNavLink></NavItem>
            <NavItem><StyledNavLink onClick={() => setTab('services')}>Tlačidlá servisov</StyledNavLink></NavItem>
            <NavItem><StyledNavLink onClick={() => setTab('article-detail')}>Navigácia v jednotivej správe</StyledNavLink></NavItem>
            <NavItem><StyledNavLink onClick={() => setTab('additional-info')}>Práca s doplnkovými informáciami k správe</StyledNavLink></NavItem>
            <NavItem><StyledNavLink onClick={() => setTab('copy')}>Kopírovanie obsahu správy</StyledNavLink></NavItem>
            <NavItem><StyledNavLink onClick={() => setTab('favorites')}>Obľúbené správy</StyledNavLink></NavItem>
            <NavItem><StyledNavLink onClick={() => setTab('search')}>Vyhľadávanie</StyledNavLink></NavItem>
            <NavItem><StyledNavLink onClick={() => setTab('time-range')}>Voľba časového rozsahu</StyledNavLink></NavItem>
            <NavItem><StyledNavLink onClick={() => setTab('keywords')}>Vyhľadávanie kľúčových slov</StyledNavLink></NavItem>
            <NavItem><StyledNavLink onClick={() => setTab('advanced-search')}>Ďalšei možnosti vyhľadávania</StyledNavLink></NavItem>
        </Flex>
        {'login' === tab && <Flex flex={1} flexDirection="column" alignItems="start">
            <h2>Prihlásenie:</h2>

            <p>Vstup do nového distribučného rozhrania agentúrneho spravodajstva SITA je z beta verzie novej stránky SITA na adrese:</p>
            <p><a href="https://in.sita.sk" rel="noreferrer">https://in.sita.sk</a></p>
            <p>Prihlasovací dialóg sa nachádza pri desktopovom aj mobilnom rozložení stránky v pravom
                hornom rohu pod ikonkou panáčika <img alt="" src={require('../../assets/help/00.jpg').default} />.</p>
        </Flex>}
        {'basics' === tab && <Flex flex={1} flexDirection="column" alignItems="start">
            <h2>Základná obrazovka:</h2>

            <p>Po prihlásení sa zobrazí základná obrazovka s vyhľadávacím poľom, voľbou časového rozsahu,</p>
            <p>Základnými navigačnými prvkami a výpisom titulkov najnovších správ.</p>
            <img alt="" src={require('../../assets/help/01.jpg').default} className="img-fluid" />
        </Flex>}
        {'article-list' === tab && <Flex flex={1} flexDirection="column" alignItems="start">
            <h2>Výpis správ:</h2>

            <p>Systém vypíše titulky 30 najnovších správ. Ďalšie sa načítajú stlačením tlačidla <img alt="" src={require('../../assets/help/02.jpg').default} /> na konci výpisu.</p>
            <p>Orientácií v zozname pomáhajú visačky obsahujúce signálne slovo z titulku správy, a podfarbené podľa príslušnosti k servisu.</p>
            <p>Po kliknutí na titulok sa zobrazí obsah správy – v prípade desktopu v pravej časti stránky,  v prípade mobilného zariadenia v samostatnom okne.</p>
            <p>Na desktope ostáva správa zobrazená až do momentu, kým nie je zobrazená ďalšia správ.</p>
            <p>Na mobilnom zariadení je potrebné plávajúce okno s textom správy  uzavrieť ťapnutím na "&lt; Späť" v ľavom hornom rohu a vrátiť sa tak na zoznam správ:</p>
            <img alt="" src={require('../../assets/help/03.jpg').default}/>
        </Flex>}
        {'navigation' === tab && <Flex flex={1} flexDirection="column" alignItems="start">
            <h2>Navigačné prvky výpisu správ:</h2>

            <p>Systém v ľavej časti poskytuje informáciu o počte správ ktoré vyhovujú daným podmienkam. V základnom pohľade sa zobrazujú všetky správy za aktuálny a predchádzajúci deň.</p>
            <p>V desktopovom zobrazení sú  v pravej časti filtre servisov a dodatočné funkčné tlačidlá (Aktuálne a Obľúbené).</p>
            <p>V Mobilnom zariadení je potrebné tento zoznam vyvolať ťapnutím na tlačidlo Servisy:</p>

            <p>
                <img alt="" src={require('../../assets/help/04.jpg').default}/>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <img alt="" src={require('../../assets/help/05.jpg').default}/>
            </p>

            <p>V zozname vyberieme vyberieme požadovaný servis. Zoznam ostáva otvorený, kým ho nezavrieme ťapnutím na miesto obrazovky, kde nie je žiaden navigačný prvok:</p>
            <img alt="" src={require('../../assets/help/06.jpg').default}/>
        </Flex>}
        {'services' === tab && <Flex flex={1} flexDirection="column" alignItems="start">
            <h2>Tlačidlá servisov:</h2>

            <img alt="" src={require('../../assets/help/07.jpg').default}/>

            <p>Filtrujú správy po servisoch pričom rešpektujú ostatné zvolené filtre ako je dátumový rozsah a vyhľadané slovo či reťazec.</p>
            <p>Tlačidlo <img alt="" src={require('../../assets/help/08.jpg').default}/> zobrazí správy zo všetkých servisov, opäť pri rešpektovaní ostatných zvolených filtre ako je dátumový rozsah a vyhľadané slovo či reťazec.</p>
            <p>Tlačidlo <img alt="" src={require('../../assets/help/09.jpg').default}/> občerství zoznam správ, pri zohľadnení všetkých nastavených filtrov - dátumový rozsah, servis, vyhľadané slovo či reťazec.</p>
            <p>Tlačidlo <img alt="" src={require('../../assets/help/10.jpg').default}/> zruší všetky nastavené filtre a zobrazí základný zoznam správ ako pri prihlásení do systému. To znamená najnovšie správy zo všetkých servisov.</p>
        </Flex>}
        {'article-detail' === tab && <Flex flex={1} flexDirection="column" alignItems="start">
            <h2>Navigácia v jednotlivej správe:</h2>

            <p>Výpis správy okrem samotného titulku a textu zobrazuje v modrom poli doplnkové dáta k správe: časovú značku, autorské značky a zaradenie do servisu.</p>
            <p>V spodnom riadku modrého poľa sú vypísané kľúčové slová správy. Tieto sú aktívne a po kliknutí na kľúčové slovo sa vyvolá vyhľadávanie tohto pojmu pri rešpektovaní ostatných parametrov vyhľadávania (časový rozsah a servis)</p>
            <p>Systém pri zobrazení správy vyvolá vyhľadávanie súvisiacich dát v ďalších systémoch a to práve na základe zvolených kľúčových slov, názvom firiem a inštitúcií.</p>
            <p>K správe sa zobrazia:</p>
            <ul>
                <li>Súvisiace správy SITA</li>
                <li>Súvisiace správy z iných médií</li>
                <li>Súvisiace fotografie (pokiaľ existujú)</li>
                <li>Súvisiace osobnosti (pokiaľ existujú) – profil z wikipedie</li>
                <li>Súvisiace firmy (pokiaľ existujú) – základné údaje z OR SR a účtovných závierok</li>
            </ul>
            <p>Na zjednodušený prechod k týmto údajom slúži navigačná lišta nad titulkom správy:</p>

            <img alt="" src={require('../../assets/help/22.png').default}/>

            <p>Lišta sa dynamicky mení - vždy obsahuje iba linky na existujúce doplnkové dáta.</p>
        </Flex>}
        {'additional-info' === tab && <Flex flex={1} flexDirection="column" alignItems="start">
            <h2>Obľúbené správy:</h2>

            <p>Práca s doplnkovými informáciami k správe:</p>
            <h3>Súvisiace správy SITA</h3>
            <p>Prvok zobrazuje 5 súvisiacich správ SITA vyhľadaných na základe kľúčových slov pôvodnej správy.</p>
            <p>Kliknutie na titulok vyvolá zobrazenie obsahu správy v plávajúcom okne.</p>
            <p>Kliknutie na zobraziť viac prenesie kľúčové slová pôvodnej správy do vyhľadávacieho poľa a vykoná vyhľadávanie. Výsledkom je nový zoznam správ, ktoré vyhovujú týmto vyhľadávacím podmienkam.</p>
            <h3>Súvisiace správy z médií</h3>
            <p>Prvok zobrazuje 5 súvisiacich správ z relevantných médií vyhľadaných na základe kľúčových slov pôvodnej správy.</p>
            <p>Kliknutie na titulok vyvolá zobrazenie obsahu správy v plávajúcom okne.</p>
            <p>Kliknutie na zobraziť viac prenesie vyhľadávací reťazec zostavený z  titulku pôvodnej správy. Do systému monitoringu médií Searchin.sk, kde sa vykoná vyhľadávanie nad viac ako 1400 jednotlivými zdrojmi.</p>
            <p>Toto platí pokiaľ máte  oprávnenie systém Searchin používať. Prácu v systéme Searchin Vám uľahčí podrobný návod na používanie. V základných črtách sa však podobá práci v in.sita.sk</p>
            <h3>Súvisiace foto</h3>
            <p>Zobrazuje fotografie z fotobanky SITA, ktoré majú zhodu s kľúčovými slovami správy. Po kliknutí na malý náhľad sa zobrazí veľký náhľad s popisom.</p>
            <h3>Súvisiace osobnosti</h3>
            <p>Zobrazuje profil osobnosti zo slovenskej wikipedie. Pokiaľ je v správe otagovaných viacero osobností, medzi ich profilmi je možné prepínať kliknutím na tlačidlo s menom osobnosti v pravom hornom roku rohu.</p>
            <p>Tlačidlo zobraziť viac smeruje na profil osobnosti na wikipedii.</p>
            <h3>Súvisiace firmy</h3>
            <p>Zobrazuje základné informácie o firme a jej hospodárskych výsledkoch. Pokiaľ je v správe otagovaných viacero firiem, alebo inštitúcií, medzi ich profilmi je možné prepínať kliknutím na tlačidlo s návom firmy/inštitúcie v pravom hornom roku rohu.</p>
            <p>Tlačidlo zobraziť viac smeruje na profil spoločnosti na stránke foaf.sk</p>
        </Flex>}
        {'copy' === tab && <Flex flex={1} flexDirection="column" alignItems="start">
            <h2>Kopírovanie obsahu správy:</h2>

            <p><img alt="" src={require('../../assets/help/11.jpg').default}/> - Po stlačení tlačidla sa Titulok a telo správy nakopírujú do systémovej schránky (obdoba funkcie CTRL+C). Systém potvrdí nakopírovanie v zelenom obdĺžniku v pravom hornom rohu displeja.</p>
        </Flex>}
        {'favorites' === tab && <Flex flex={1} flexDirection="column" alignItems="start">
            <h2>Obľúbené správy:</h2>

            <p><img alt="" src={require('../../assets/help/12.jpg').default}/> - Po stlačení sa správy pridá do osobitného zoznamu správ, ku ktorým sa chcete neskôr vrátiť.</p>
            <p>Pri pridaní sa tlačidlo zmení nasledovným spôsobom <img alt="" src={require('../../assets/help/13.jpg').default}/> a kliknutie vyradí správu zo zoznamu obľúbených správ.</p>
            <p>Obľúbené správy zobrazíme tlačidlom na konci zoznamu servisov <img alt="" src={require('../../assets/help/14.jpg').default}/>, tlačidlo zároveň nesie informácie o počte správ zaradených v tomto zozname.</p>
        </Flex>}
        {'search' === tab && <Flex flex={1} flexDirection="column" alignItems="start">
            <h2>Vyhľadávanie slov a reťazcov a voľba časového rozsahu zobrazených správ:</h2>

            <p>Pri desktopovom zobrazení sú prvky vyhľadávania a časového rozsahu priamo viditeľné pri mobilnom zobrazení, je ich potrebné vyvolať stlačením ikony lupy:</p>
            <p>
                <img alt="" src={require('../../assets/help/15.jpg').default}/>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <img alt="" src={require('../../assets/help/16.jpg').default}/>
            </p>
        </Flex>}
        {'time-range' === tab && <Flex flex={1} flexDirection="column" alignItems="start">
            <h2>Voľby časového rozsahu vyhľadávania:</h2>

            <p>Systém má preddefinovaný najbežnejší rozsah vyhľadávania t.z aktuálne správy.</p>
            <p>Kliknutie na dátumové pole vyvolá okno kde je možné zvoliť si niektorý z preddefinovaných dátumových rozsahov, alebo si samostatne zvoliť konkrétny časový rozsah.</p>
            <p>V prípade mobilných zariadení systém využíva dátumové dialógy príslušného zariadenia  vrípade desktopu je vytvorený osobitný systém pre efektívnejšie zadávanie väčších časových rozsahov:</p>
            <img alt="" src={require('../../assets/help/17.jpg').default}/>

            <p>Začiatok rozsahu volíme kliknutím na konkrétny deň v poli Dátum od a koniec rozsahu kliknutím na konkrétny deň v poli Dátum do.</p>
            <p>Pokiaľ potrebujeme stanoviť širší časový interval klikneme v poli nad kalendárom na text mesiaca:</p>
            <img alt="" src={require('../../assets/help/18.jpg').default}/>

            <p>A v zobrazenom pohľade zvolíme skratku mesiaca:</p>
            <img alt="" src={require('../../assets/help/19.jpg').default}/>

            <p>Obdobne postupujeme pri voľba roka.</p>
            <p>Po úprave dátumového rozsahu ho musíme <img alt="" src={require('../../assets/help/20.jpg').default}/>.</p>
            <p>A následne uskutočniť vyhľadanie s novým parametrom (časovým rozsahom) stlačením tlačidla Hľadaj:</p>
            <img alt="" src={require('../../assets/help/21.jpg').default}/>
            <p>Vyhľadávanie pri zmena časového rozsahu zachováva ostatné parametre vyhľadávania (slová, reťazce a servis)</p>
        </Flex>}
        {'keywords' === tab && <Flex flex={1} flexDirection="column" alignItems="start">
            <h2>Vyhľadávanie konkrétnych kľúčových slov a reťazcov kľúčových slov:</h2>

            <p>Jednotlivé slová sa zadávajú do obdĺžnikového poľa v hornej časti stránky.</p>
            <p>Pokiaľ je prázdne vyhľadávajú sa všetky správy pri zohľadnení časového rozsahu a servisu</p>
            <p>Východiskovým operátorov pri vyhľadávaní viacerých pojmov je operátor OR.</p>
            <p>To znamená že výsledkom vyhľadávania reťazca Ivan Korčok budú všetky správy v ktorých sa vyskytuje slovo Ivan alebo Korčok.</p>
            <p>Pokiaľ chceme presné vyhľadanie správ kde je menovaný výlučne Ivan Korčok, tak dotaz bude mať podobu +Ivan +Korčok</p>
            <p>Ak chceme správy o všetkých Korčokoch okrem Ivana dotaz má podobu -Ivan +Korčok</p>
            <p>Systém sa snaží zabezpečiť ohýbanie slov a zabezpečiť aj vyhľadanie vyskloňovaných pojmov, ale predovšetkým pri menách a názvoch to nemusí byť spoľahlivé riešenie a v týchto prípadoch odporúčame použiť hviezdičku. </p>
        </Flex>}
        {'advanced-search' === tab && <Flex flex={1} flexDirection="column" alignItems="start">
            <h2>Základné informácie o systéme SearchIn</h2>

            {/*<StyledImg src={require('../assets/help/help01.png')}/>*/}
            <p>Systém ponúka aj ďalšie možnosti vyhľadávania, uvedené v podrobnom návode:</p>
            <h2>Jednoslovné dotazy</h2>
            <pre>slovo</pre>
            <p>Výsledkom je výpis článkov, ktoré obsahujú vyhľadávaný termín v pôvodnej aj vyskloňovanej alebo vyčasovanej podobe. Neprihliada sa ani na veľké či malé písmená.</p>
            <pre>"fráza" – presné vyhľadávanie</pre>
            <p>Výsledkom sú články, ktoré obsahujú presnú podobu slova, alebo slovného spojenia aj so zohľadnením veľkých a malých písmen</p>
            <p><b>Upozornenie:</b> Operátorom nie sú typografické úvodzovky – horné a dolné.</p>
            <pre>slov*</pre>
            <p>operátor * nahrádza ľubovoľný počet znakov nasledujúcich za koreňom slova.</p>
            <p>Výsledkom sú články ktoré obsahujú pojmy začínajúce znakmi slov – čiže napr. slovo, Slovák, slovenčina, slovník atď. Využitie je vhodné pri hľadaní vlastných mien a priezvisk.</p>
            <pre>sl?v?</pre>
            <p>operátor ? nahrádza práve jeden znak kdekoľvek v slove, v jednom slove môže byť použitý viackrát. Využitie je vhodné najmä pri hľadaní vlastných mien a priezvisk, kde si nie sme istí presnou podobou mena.</p>
            <p>Výsledkom sú články obsahujúce napr. slovo, slová, sláva, sliva</p>
            <p><b>Upozornenie:</b> Použitie presného vyhľadávania s pomocou úvodzoviek, sa logicky vylučuje s použitím operátorov * a ?.</p>
            <h2>Vyhľadávanie kombinácií viacerých slov</h2>
            <h2>Operátory OR, AND, NOT</h2>
            <h3>Operátor OR (|) (ctrl+alt+w)</h3>
            <p>je predvolený operátor systému a teda nie je potrebné ho zadávať</p>
            <pre>slovo1 slovo2</pre>
            <p>Výsledkom sú články, ktoré obsahujú slovo1 alebo slovo 2 (v základnej, vyskloňovanej alebo vyčasovanej podobe)</p>
            <h3>operátor AND (+)</h3>
            <pre>+slovo1 +slovo2 (identické so zadaním slovo1 AND slovo2)</pre>
            <p>Výsledkom sú články, ktoré obsahujú slovo1, aj slovo2</p>
            <pre>slovo1 +slovo2 (identické so zadaním slovo2 OR slovo1)</pre>
            <p>Výsledkom sú články, ktoré obsahujú slovo2 a môžu obsahovať slovo1, ktoré bude tiež podfarbené, články s oboma slovami budú mať vyššiu relevanciu</p>
            <h3>operátor NOT (-)</h3>
            <pre>+slovo1 -slovo2 (identické so zadaním slovo1 NOT slovo2)</pre>
            <p>Výsledkom sú články, ktoré obsahujú slovo2 a neobsahujú slovo1</p>
            <h3>Ďalšie operátory</h3>
            <pre>"slovo1 slovo2" – presné vyhľadávanie</pre>
            <p>výsledkom sú články, ktoré obsahujú presnú podobu slovného spojenia aj so zohľadnením veľkých a malých písmen</p>
            <pre>"slovo1 slovo2"~3</pre>
            <p>Operátor ~ určuje v prípade frázy maximálnu prípustnú vzdialenosť medzi slovami</p>
            <p>Výsledkom sú články, ktoré obsahujú slovo1 a slovo2, pričom medzi nimi nie sú viac ako 3 iné slová</p>
            <pre>slovo1 NOT (slovo2 slovo3)</pre>
            <p>() vytvorí skupinu slov, na ktoré sa vzťahuje operácia</p>
            <p>Výsledkom sú články, ktoré obsahujú slovo1 ale neobsahujú ani slovo2, ani slovo3</p>
        </Flex>}
    </Flex>
}

export default Help;
