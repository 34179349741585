import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
    font-size: .9rem;
`;

const Big = ({children}) => (
    <StyledSpan>{children}</StyledSpan>
)

export default Big;