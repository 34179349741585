import React from 'react';
import {Button} from "reactstrap";
import Back from '../../Atoms/Icons/Back';
import {colors} from "../../../config/theme";

const Dashboard = ({onClick}) => (
    <Button
        color="link"
        className="d-flex align-items-center"
        onClick={onClick}
    >
        <Back fill="none" stroke={colors.black} size={20}/>
        Spať
    </Button>
);

export default Dashboard;
