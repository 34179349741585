import React, {useContext} from 'react';
import {EosIcon, Flex} from "../../Atoms";
import {colors} from "../../../config/theme";
import LinkIcon from "../../Atoms/Icons/Link";
import Favorites from "../../Atoms/Icons/Favorites";
import {ToastContainer} from "react-toastr";
import {UserContext} from "../../../context";
import {useMutation} from "@apollo/client";
import {NOTES_ADD_FAVORITE, NOTES_REMOVE_FAVORITE} from "../../../queries";
import {Button} from "reactstrap";

const AddToFavorites = ({id}) => {
    const {user, notes} = useContext(UserContext);

    const [addFavorite] = useMutation(NOTES_ADD_FAVORITE, {
        context: {
            headers: {
                Authorization: user.token
            }
        },
        onCompleted: () => notes.refetch(),
    });
    const [removeFavorite] = useMutation(NOTES_REMOVE_FAVORITE, {
        context: {
            headers: {
                Authorization: user.token
            }
        },
        onCompleted: () => notes.refetch(),
    });

    return <>
        {-1 === user.notes.favorites.map(favorite => favorite.id).indexOf(id)
            ? <Button color="link" className="font-weight-bold align-items-center d-flex" onClick={() => addFavorite({
                variables: {
                    article: parseInt(id),
                },
            })}>
                <Favorites size={16} color={colors.silver} className="me-2"/>
                Pridať medzi obľúbené
            </Button>
            : <Button color="link" className="font-weight-bold text-danger align-items-center d-flex" onClick={() => removeFavorite({
                variables: {
                    article: parseInt(id),
                }
            })}>
                <Favorites size={16} color={colors.crimson} className="me-2"/>
                Odstrániť z obľúbených
            </Button>
        }
    </>
};

const ArticleActions = ({id, title, content}) => {
    let container;

    return <Flex className="mb-4">
        <Flex onClick={() => {
            navigator.clipboard.writeText(`${title}\n\n${content}`);
            container.success(<p>Článok bol úspešne skopírovaný</p>);
        }}>
            <Button color="link" className="font-weight-bold align-items-center d-flex">
                <EosIcon className="me-2" color={colors.silver}>copy_all</EosIcon>
                Kopírovať
            </Button>
        </Flex>
        <Flex>
            <Button color="link" className="font-weight-bold align-items-center d-flex">
                <LinkIcon size={16} color={colors.silver} className="me-2"/>
                Pôvodný materiál
            </Button>
        </Flex>
        <Flex>
            <AddToFavorites id={id}/>
        </Flex>
        <ToastContainer ref={ref => container = ref} className="toast-top-right" />
    </Flex>
}

export default ArticleActions;
