import React, {useContext} from "react";
import {ArticlesContext} from "../../../context";
import styled from "styled-components";
import {EosIcon} from "../../Atoms";

const StyledRefetch = styled(EosIcon)`
    cursor: pointer;
`;

const RefetchArticles = () => {
    const {results: {refetch}} = useContext(ArticlesContext);

    return <StyledRefetch className="fw-bold fs-2 mx-3" onClick={() => refetch()}>sync</StyledRefetch>
}

export default RefetchArticles;
