import PhotoContext from "../PhotoContext";
import {useContext} from "react";
import {useQuery} from "@apollo/client";
import {SEARCH_PHOTOS} from "../../queries";
import {photoClient} from "../../clients";
import moment from "moment";
import {ArticlesContext, UserContext} from "../index";
import PropTypes from "prop-types";

const propTypes = {
    tags: PropTypes.array.isRequired,
    persons: PropTypes.array.isRequired,
    companies: PropTypes.array.isRequired,
};

const defaultProps = {
    tags: [],
    persons: [],
    companies: []
};

const PhotoProvider = ({tags, persons, companies, children}) => {
    const {user} = useContext(UserContext);
    const {filter: {filter: {date: {from, to}}}} = useContext(ArticlesContext);

    const keywords = [
        ...tags.map(tag => tag.name),
        ...persons,
        ...companies.map(company => company.name)
    ];

    const {data} = useQuery(SEARCH_PHOTOS, {
        client: photoClient,
        context: {
            headers: {
                Authorization: user.token
            }
        },
        variables: {
            query: `"${keywords.join('" "')}"`,
            startAt: moment(from).subtract(7, 'd').format('YYYY-MM-DD'),
            endAt: to,
            limit: 25
        }
    });

    return <PhotoContext.Provider value={{photos: data?.search || []}}>
        {children}
    </PhotoContext.Provider>
}

PhotoProvider.propTypes = propTypes;

PhotoProvider.defaultProps = defaultProps;

export default PhotoProvider;
