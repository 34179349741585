import * as React from 'react';
import {Footer, Jumbotron, CardList, TeamJumbotron} from "../Organisms";
import {colors} from "../../config/theme";
import {Button, Container} from "reactstrap";
import styled from "styled-components";
import bannerImg from "../../assets/img/banner2.png";
import {Flex} from "../Atoms";
import products from "../../config/products";
import {Header} from "../Organisms/Homepage";
import avatar from "../../assets/img/male.png";

const StyledHeader = styled(Flex)`
    background: url(${bannerImg});
    background-size: cover;
    color: ${colors.white};
    height: 613px;
    margin-bottom: -150px;
`;

const member = {
    active: true,
    img: avatar,
    title: 'MICHAL\nPOBEHA',
    content: 'šéfredaktor SITA'
};
const About = () => (
    <>
        <StyledHeader flex="1 0 auto" flexDirection="column">
            <Flex flex={1} flexDirection="column">
                <Header/>

                <Container>
                    <Flex>
                        <Flex className="pt-5" flexDirection="column" justifyContent="center" alignItems="start" md={4}>
                            <h1 className="font-weight-light"><strong>INFORMÁCIE</strong><br />na jednom mieste</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,  quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <Button color="froly">VIAC INFO</Button>
                        </Flex>
                    </Flex>
                </Container>
            </Flex>
        </StyledHeader>
        <Container>
            <CardList items={Array(5).fill(member, 0)}/>
        </Container>
        <TeamJumbotron color={colors.tradewind} secondaryColor={colors.hippieBlue}/>
        <TeamJumbotron color={colors.lightningYellow} secondaryColor={colors.goldTips} reverse={true}/>
        <Jumbotron color={colors.froly} secondaryColor={colors.roman}/>
        <Container>
            <CardList items={products.map(product => ({
                ...product,
                backgroundColor: product.color,
                color: colors.white
            }))}/>
        </Container>
        <Footer/>
    </>
)

export default About;
