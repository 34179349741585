import * as React from 'react';
import {Col, Container, Row} from "reactstrap";
import ContactForm from "./ContactForm";
import styled from 'styled-components';
import {colors} from "../../config/theme";
import {ClientBrand, Flex} from "../Atoms";
import {Link} from "react-router-dom";

const StyledFooter = styled.div`
    background-color: ${colors.black} !important;
    color: ${colors.silver};
`;

const StyledLink = styled.a`
    color: ${colors.mercury};
    font-size: 14px;
    font-weight: bold;
`;

const StyledRouterLink = styled(Link)`
    color: ${colors.mercury};
    font-size: 14px;
    font-weight: bold;
`;

const StyledAgencyLink = styled.a`
    color: ${colors.mercury};
    font-size: 14px;
`;

const StyledFooter2 = styled(Flex)`
`;

const Footer = () => (
    <>
        {/*<StyledFooter>*/}
        {/*    <Container>*/}
        {/*        <Row className="pt-5">*/}
        {/*            <Col lg={4} className="mb-5">*/}
        {/*                <h2 className="mb-5">Napíšte nám</h2>*/}
        {/*                <ContactForm/>*/}
        {/*            </Col>*/}
        {/*            <Col xs={6} md={3} lg={2} className="border-left mb-5">*/}
        {/*                <h2 className="mb-5">Sita</h2>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk">vo <strong>financiách</strong></StyledAgencyLink></p>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk">v <strong>zdravotníctve</strong></StyledAgencyLink></p>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk">naša <strong>zdravotníctve</strong></StyledAgencyLink></p>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk">náš <strong>zdravotníctve</strong></StyledAgencyLink></p>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk">v <strong>zdravotníctve</strong></StyledAgencyLink></p>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk">v <strong>zdravotníctve</strong></StyledAgencyLink></p>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk"><strong>webnoviny</strong></StyledAgencyLink></p>*/}
        {/*            </Col>*/}
        {/*            <Col xs={6} md={3} lg={2} className="border-left mb-5">*/}
        {/*                <h2 className="mb-5">Pre agentúry</h2>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk">vo <strong>financiách</strong></StyledAgencyLink></p>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk">v <strong>zdravotníctve</strong></StyledAgencyLink></p>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk">naša <strong>zdravotníctve</strong></StyledAgencyLink></p>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk">náš <strong>zdravotníctve</strong></StyledAgencyLink></p>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk">v <strong>zdravotníctve</strong></StyledAgencyLink></p>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk">v <strong>zdravotníctve</strong></StyledAgencyLink></p>*/}
        {/*                <p><StyledAgencyLink href="http://www.sita.sk"><strong>webnoviny</strong></StyledAgencyLink></p>*/}
        {/*            </Col>*/}
        {/*            <Col xs={6} md={3} lg={2} className="border-left mb-5">*/}
        {/*                <h2 className="mb-5">Pre médiá</h2>*/}
        {/*                <p><StyledLink href="http://www.sita.sk">novinky</StyledLink></p>*/}
        {/*                <p><StyledRouterLink to="/about">o nás</StyledRouterLink></p>*/}
        {/*                <p><StyledLink href="http://www.sita.sk">VOP</StyledLink></p>*/}
        {/*                <p><StyledLink href="http://www.sita.sk">fakturačné údaje</StyledLink></p>*/}
        {/*                <p><StyledLink href="http://www.sita.sk">GDPR</StyledLink></p>*/}
        {/*                <p><StyledLink href="http://www.sita.sk">ponuka práce</StyledLink></p>*/}
        {/*                <p><StyledLink href="http://www.sita.sk">kontakt na redakciu</StyledLink></p>*/}
        {/*            </Col>*/}
        {/*            <Col xs={6} md={3} lg={2} className="border-left mb-5">*/}
        {/*                <h2 className="mb-5">Pre firmy</h2>*/}
        {/*                <p><StyledLink href="http://www.sita.sk">novinky</StyledLink></p>*/}
        {/*                <p><StyledLink href="http://www.sita.sk">o nás</StyledLink></p>*/}
        {/*                <p><StyledLink href="http://www.sita.sk">VOP</StyledLink></p>*/}
        {/*                <p><StyledLink href="http://www.sita.sk">fakturačné údaje</StyledLink></p>*/}
        {/*                <p><StyledLink href="http://www.sita.sk">GDPR</StyledLink></p>*/}
        {/*                <p><StyledLink href="http://www.sita.sk">ponuka práce</StyledLink></p>*/}
        {/*                <p><StyledLink href="http://www.sita.sk">kontakt na redakciu</StyledLink></p>*/}
        {/*            </Col>*/}
        {/*        </Row>*/}
        {/*    </Container>*/}
        {/*</StyledFooter>*/}
        <Container>
            <StyledFooter2 flexDirection="column">
                <Flex flex={1} justifyContent="center" className="fw-light text-center m-2 mt-3">&copy; 2020 sixmedia s. r. o., SITA Slovenská tlačová agentúra a.s.</Flex>
                <Flex justifyContent="center">
                    <ClientBrand color={colors.black} className="mx-0 mb-3"/>
                </Flex>
            </StyledFooter2>
        </Container>
    </>
);

export default Footer;
