import React from 'react';
import {useField} from "formik";
import {Input} from "reactstrap";

const FormikInput = (props) => {
    const [
        field,
        {touched, error},
    ] = useField(props);

    return <Input {...field} {...props} invalid={Boolean(touched && error && Object.keys(error).length)}/>
};

export default FormikInput;
