import {ArticlesCard} from "../../index";
import React, {useState} from "react";
import {ShowMore} from "../../../Molecules";
import {NOTES_PREVIEW} from "../../../../queries";
import {useQuery} from "@apollo/client";
import moment from "moment";
import {QuickModal} from "../../../index";
import styled from 'styled-components';
import {colors} from "../../../../config/theme";
import {notesAnonymousClient} from "../../../../clients";

const StyledA = styled.a`
    color: ${colors.outrageousOrange}
`;

const SitaService = () => {
    const [isOpen, setIsOpen] = useState(false);

    const {data} = useQuery(NOTES_PREVIEW, {
        client: notesAnonymousClient,
        variables: {
            query: '*',
            to: moment().format('YYYY-MM-DD'),
            from: moment().subtract(7, 'd').format('YYYY-MM-DD'),
            limit: 5
        }
    })

    return <>
        <ArticlesCard
            title="Najnovšie správy SITA"
            articles={data?.preview.data.map(article => ({
                ...article,
            }))}
            onSelect={() => setIsOpen(true)}
            more={<a href="https://in.sita.sk/" target="_blank" rel="noreferrer">
                <ShowMore/>
            </a>}
        />
        {isOpen && (
            <QuickModal
                title="Najnovšie správy SITA"
                onClose={() => setIsOpen(!isOpen)}
            >
                <p>Na prehliadanie obsahu je potrebné prihlásiť sa.</p>
                <p>Pokiaľ nie ste ešte zaregistrovaní, prístup Vám poskytne Oddelenie obchodu a marketingu SITA.</p>

                <p>
                    obchod@sita.sk<br/>
                    +421 2 2062 0311<br/>
                    +421 918 800 413
                </p>

                <p>alebo použite <StyledA href={"#contact"} onClick={() => setIsOpen(false)}>kontaktný formulár</StyledA></p>
            </QuickModal>
        )}
    </>
}

export default SitaService;
