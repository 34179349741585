import * as React from 'react';
import {Footer, Jumbotron, CardList, Services} from "../Organisms";
import {colors} from "../../config/theme";
import {Button, Container} from "reactstrap";
import styled from "styled-components";
import bannerImg from "../../assets/img/banner2.png";
import {Flex} from "../Atoms";
import products from "../../config/products";
import {Header} from "../Organisms/Homepage";
import avatar from "../../assets/img/male.png";
import Service from "../Organisms/Service";

const StyledHeader = styled(Flex)`
    background: url(${bannerImg});
    background-size: cover;
    color: ${colors.white};
    height: 613px;
`;

const BusinessInsiderPage = () => (
    <>
        <StyledHeader flex="1 0 auto" flexDirection="column" className="mb-6">
            <Flex flex={1} flexDirection="column">
                <Header/>

                <Container>
                    <Flex>
                        <Flex className="pt-5" flexDirection="column" justifyContent="center" alignItems="start" md={4}>
                            <h1 className="font-weight-light"><strong>INFORMÁCIE</strong><br />na jednom mieste</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,  quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <Button color="froly">VIAC INFO</Button>
                        </Flex>
                    </Flex>
                </Container>
            </Flex>
        </StyledHeader>

        <Container>
            <Service service={{
                title: 'SITA Biznis insider',
                content: (
                    <Flex flex={{
                        xs: {flexDirection: 'column'},
                        md: {flexDirection: 'row'}
                    }}>
                        <Flex flexDirection="column" className="pe-4" flex={1}>
                            <p>Prepojte vašu spoločnosť so značkou SITA Biznis insider a prostredníctvom palety komunikačných nástrojov vyťažte maximum. Ultimátna šírka informačných a komunikačných nástrojov so skvelým pomerom cena/výkon. </p>
                        </Flex>
                    </Flex>
                )
            }} active={true}/>
            <Service service={{
                title: 'Komplexný informačný servis',
                content: (
                    <Flex flex={{
                        xs: {flexDirection: 'column'},
                        md: {flexDirection: 'row'}
                    }}>
                        <Flex flexDirection="column" className="pe-4" flex={1}>
                            <ul>
                                <li>Aktuálne správy sú kontextuálne prepojené s ďalšími službami – monitoringom médií, fotobankou, personálnymi a firemnými informáciami.</li>
                                <li>Kompletný prístup do všetkých informačných servisov SITA – ekonomický, domáci, zahraničný, regionálny a šport.</li>
                                <li>Spravodajstvo je dostupné cez responzívne klientské rozhranie navrhnuté na efektívnu prácu na mobilnom telefóne, tablete, notebooku aj stolnom počítači.</li>
                            </ul>
                        </Flex>
                        <Flex>
                            <img className="img-fluid" src="https://place-hold.it/800x300"/>
                        </Flex>
                    </Flex>
                )
            }} active={true}/>
            <Service service={{
                title: 'Komplexný komunikačný servis',
                content: (
                    <Flex flex={{
                        xs: {flexDirection: 'column'},
                        md: {flexDirection: 'row'}
                    }}>
                        <Flex>
                            <img className="img-fluid" src="https://place-hold.it/800x300"/>
                        </Flex>
                        <Flex flexDirection="column" className="pe-4" flex={1}>
                            <ul>
                                <li>Odborná spolupráca s redakciou SITA, možnosť kreovania vlastných tém a obsahu zaujímavého pre médiá.</li>
                                <li>Poskytnutie mediálneho priestoru 3 x ročne na vyjadrenie sa predstaviteľa spoločnosti ako odborného garanta do redakčnej správy SITA, ktorej obsah úzko súvisí so zameraním firmy.</li>
                                <li>Zaradenie informácie o konaní tlačovej konferencie do interného prehľadu konferencií, ktoré odoberajú od SITA zmluvné média.</li>
                            </ul>
                        </Flex>
                    </Flex>
                )
            }} active={true}/>
            <Service service={{
                title: 'Bonusové informačné služby',
                content: (
                    <Flex flex={{
                        xs: {flexDirection: 'column'},
                        md: {flexDirection: 'row'}
                    }}>
                        <Flex flexDirection="column" className="pe-4" flex={1}>
                            <ul>
                                <li>Neobmedzený vstup do databázy Monitoringu médií s možnosťou vyhľadávania informácií zo slovenských médií podľa zadaných kľúčových slov a hesiel.</li>
                                <li>Denný manažérsky prehľad Slovak a.m. (SK alebo EN) zasielaný e-mailom do 8:00. Obsahuje ekonomické, podnikové, finančné a politické správy zo Slovenska.</li>
                                <li>Možnosť umiestnenie reklamného bannera v špecializovaných manažérskych prehľadoch, napr. Slovak a.m., ktoré sú distribuované na dennej / týždennej báze biznis klientom.</li>
                            </ul>
                        </Flex>
                        <Flex>
                            <img className="img-fluid" src="https://place-hold.it/800x300"/>
                        </Flex>
                    </Flex>
                )
            }} active={true}/>
            <Service service={{
                title: 'Služby PR siete Webnoviny.sk ',
                content: (
                    <Flex flex={{
                        xs: {flexDirection: 'column'},
                        md: {flexDirection: 'row'}
                    }}>
                        <Flex>
                            <img className="img-fluid" src="https://place-hold.it/800x300"/>
                        </Flex>
                        <Flex flexDirection="column" className="pe-4" flex={1}>
                            <ul>
                                <li>Neobmedzený počet PR správ na stránke Prservis.sk.</li>
                                <li>5 PR správ / 12 mesiacov v plnom znení v informačnom servise SITA</li>
                                <li>5 PR správ / 12 mesiacov na spravodajskom  portáli Webnoviny.sk a sieti partnerských portáloch.</li>
                                <li>5 PR správa / 12 mesiacov na tématickom portáli zo skupiny Webnoviny, ktorý priamo súvisí s vašim zameraním. </li>
                                <li>5 PR TOP správ / 12 mesiacov na spravodajských portáloch Topky.sk, Pluska.sk, TA3.com, Pravda.sk, Sme.sk, Tyzden.sk a Noviny.sk </li>
                            </ul>
                        </Flex>
                    </Flex>
                )
            }} active={true}/>
            <Service service={{
                title: 'Rozšírené PR služby',
                content: (
                    <Flex flex={{
                        xs: {flexDirection: 'column'},
                        md: {flexDirection: 'row'}
                    }}>
                        <Flex flexDirection="column" className="pe-4" flex={1}>
                            <ul>
                                <li>Vytvoríme 3 x Profilový článok / 12 mesiacov na portáli Webnoviny.sk v podobe rozhovoru s predstaviteľom spoločnosti vo forme otázka / odpoveď (kontent môže zahŕňať: audio-podcast, fotogalériu a video), zverejnenie informácie aj v rámci agentúrneho servisu SITA.</li>
                                <li>Realizácia troch natívnych článkov / 12 mesiacov o spoločnosti, službách alebo produktoch, ktoré sú súčasťou portfólia klienta.</li>
                                <li>Výroba výroba a prezentácia promo videa vrátane záberov z dronu.</li>
                            </ul>
                        </Flex>
                        <Flex>
                            <img className="img-fluid" src="https://place-hold.it/800x300"/>
                        </Flex>
                    </Flex>
                )
            }} active={true}/>
            <Service service={{
                title: 'Reklamné služby',
                content: (
                    <Flex flex={{
                        xs: {flexDirection: 'column'},
                        md: {flexDirection: 'row'}
                    }}>
                        <Flex>
                            <img className="img-fluid" src="https://place-hold.it/800x300"/>
                        </Flex>
                        <Flex flexDirection="column" className="pe-4" flex={1}>
                            <ul>
                                <li>Bannerová kampaň na portáli Webnoviny.sk alebo na  príslušnom portáli zo skupiny Webnoviny (formát 300 x 300 px).</li>
                                <li>Možnosť umiestnenia bannerov vrátane aktívnych odkazov na články v Newsletteroch skupiny Webnoviny.</li>
                                <li>Podpora článkov cez sociálne siete, odkaz na články cez natívnu sieť Strossle, kreatívna forma reklamy cez Instagram.</li>
                                <li>Zverejnenie loga klienta ako PARTNERA na tematickom spravodajskom webe zo skupiny Webnoviny.</li>
                            </ul>
                        </Flex>

                    </Flex>
                )
            }} active={true}/>
        </Container>


        <Jumbotron color={colors.froly} secondaryColor={colors.roman}/>
        <Container>
            <CardList items={products.map(product => ({
                ...product,
                backgroundColor: product.color,
                color: colors.white
            }))}/>
        </Container>
        <Footer/>
    </>
)

export default BusinessInsiderPage;
