import {gql} from '@apollo/client';

export default gql`query Company($id: Int!) {
    company(id: $id) {
        id
        section
        insertNumber
        companyId
        forwarded {
            id
        }
        businessNames {
            name
            endAt
        }
        dateOfEntries {
            date
        }
        registeredSeats {
            street
            number
            city
            zip
        }
        identificationNumbers {
            number
        }
        legalForms {
            title
        }
        managementBodies {
            type
            titleBefore
            name
            surname
            street
            number
            city
            startAt
            endAt
        }
        partners {
            titleBefore
            name
            surname
            corporation
            street
            number
            city
            startAt
            endAt
        }
        accountingInformation {
            id
            financialStatements {
                id
                startAt
                endAt
                financialReports {
                    id
                    financialReportTables {
                        id
                        name
                        financialReportTableItems {
                            id
                            position
                            data
                        }
                    }
                }
            }
        }
    }
}`
