import React, {useContext} from 'react';
import moment from "moment";
import {Flex} from "../../Atoms";
import Date from "../../Atoms/Icons/Date";
import styled from 'styled-components';
import {colors} from "../../../config/theme";
import Author from "../../Atoms/Icons/Author";
import Key from "../../Atoms/Icons/Key";
import {ArticlesContext} from "../../../context";
import Category from "../../Atoms/Icons/Category";

const StyledHeader = styled(Flex)`
    background-color: ${colors.scooter};
    color: white;
    border-radius: 4px;
`;

const StyledKeyword = styled.span`
    cursor: pointer;
`;

const ArticleHeader = ({service, date, authors, tags}) => {
    const {filter: {filter, setFilter}} = useContext(ArticlesContext);

    return <StyledHeader className="p-3 mb-4" flexDirection="column">
        <Flex alignItems="center" className="mb-3">
            <Flex alignItems="center" className="me-3">
                <Date size={16} fill={colors.white} className="me-2"/>
                <span>{moment(date).format('DD. MMM YYYY HH:mm')}</span>
            </Flex>
            <Flex alignItems="center" className="me-3">
                <Author size={16} fill={colors.white} className="me-2"/>
                <span>{authors.map(author => author.nick).join(', ')}</span>
            </Flex>
            <Flex alignItems="center" className="me-3">
                <Category size={16} className="me-2"/>
                <span>{service}</span>
            </Flex>
        </Flex>
        <Flex alignItems="center">
            <Flex alignItems="center" className="mr-3">
                <Key size={16} color={colors.white} fill={colors.white} className="mr-2"/>
                {tags.map((tag, index) => (
                    <React.Fragment key={index}>
                        {0 !== index && ','}
                        <StyledKeyword
                            className="ms-1"
                            onClick={() => setFilter({
                                ...filter,
                                query: tag.name
                            })}
                        >
                            {tag.name}
                        </StyledKeyword>
                    </React.Fragment>
                ))}
            </Flex>
        </Flex>
    </StyledHeader>
}

export default ArticleHeader;
