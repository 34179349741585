import React from 'react';
import styled from 'styled-components';
import {Badge, Flex} from "../Atoms";
import {colors} from "../../config/theme";
import {ShowMore} from "../Molecules";
import PropTypes from 'prop-types';

const StyledTime = styled(Flex)`
    color: ${colors.silver};
`;

const StyledArticle = styled(Flex)`
    cursor: pointer;
`;

const StyledTitle = styled(Flex)`
    font-weight: 300;
`;

const StyledImg = styled.img`
    width: 60px;
    height: 40px;
`;

const ArticlesCard = ({title, articles = [], onClick, onSelect, withBadge = true, more = null, ...rest}) => (
    <Flex flexDirection="column" {...rest} flex={1} className="my-3">
        <h2 className="border-bottom">{title}</h2>
        <Flex flexDirection="column" className="mb-3">
            {articles.map(article => (
                <StyledArticle key={article.id} className="py-2 border-bottom" alignItems="center" onClick={() => onSelect && onSelect(article.id)}>
                    {article.image && <StyledImg alt="" src={article.image} className="me-2"/>}
                    <Flex flexDirection="column">
                        {article.info && <StyledTime className="me-2">{article.info}</StyledTime>}
                        <StyledTitle flex={1} alignItems="flex-start">
                            {withBadge && <Badge className="mr-2" dangerouslySetInnerHTML={{__html: article.info}} color={colors.boulder}/>}
                            <a href={article.url} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: article.title}}/>
                        </StyledTitle>
                    </Flex>
                </StyledArticle>
            ))}
        </Flex>
        {onClick
            ? <ShowMore onClick={onClick}/>
            : more
                ? more
                : <a href={`https://www.searchin.sk/auth/${localStorage.getItem('authorization')}`} target="_blank" rel="noreferrer">
                    <ShowMore/>
                </a>
        }
    </Flex>
);

ArticlesCard.propTypes = {
    title: PropTypes.string.isRequired,
    articles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        info: PropTypes.string.isRequired
    }))
}

export default ArticlesCard;
