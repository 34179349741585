import {ssoClient} from "../../clients";
import {useContext} from "react";
import {UserContext} from "../../context";
import {useQuery} from "@apollo/client";
import {VERIFY} from "../../queries";
import {useHistory} from "react-router-dom";

const Protected = ({children}) => {
    const {user} = useContext(UserContext);
    const history = useHistory();

    console.log(user);

    const {data} = useQuery(VERIFY, {
        client: ssoClient,
        context: {
            headers: {
                Authorization: user.token
            }
        },
        variables: {
            token: user.token
        }
    });

    console.log(data);

    if (false === data?.verify) {
        history.push('/');
    }

    return children;
}

export default Protected
