import {gql} from '@apollo/client';

export default gql`query Favorites (
    $offset: Int
    $limit: Int
) {
    favorites(
        offset: $offset,
        limit: $limit
    ) {
        data {
            id
            title
            subtitle
            authors {
                nick
            }
            locale
            priority {
                name
            }
            date
            content
            categories {
                name
                description
                slug
                color
                parent {
                    name
                    description
                    slug
                    color
                }
            }
            tags {
                name
                slug
            }      
            companies {
                id
                name
            }
            persons
        }
        meta {
            offset
            limit
            count
        }

    }
}`
