import {useState} from "react";
import {ClientBrand, Flex} from "../Atoms";
import styled from 'styled-components';
import {Collapse, Navbar, NavbarToggler} from "reactstrap";
import * as React from "react";
import Hamburger from "../Atoms/Icons/Hamburger";
import {useMediaQuery} from "react-responsive/src";
import {md} from "../../responsive";

const StyledNavbarToggler = styled(NavbarToggler)`
    color: rgba(255,255,255,1) !important;
    border: 0;
`

const Header = ({actions, children}) => {
    const [collapsed, setCollapsed] = useState(true);

    const toggleNavbar = () => setCollapsed(!collapsed);

    const isMd = useMediaQuery(md);

    return <Navbar container="md" expand="md" dark={true}>
        <Flex flex="1 0 auto" alignItems="center" justifyContent="space-between" className="justify-content-md-start flex-md-grow-0">
            <Flex>
                <StyledNavbarToggler onClick={toggleNavbar} className="me-3">
                    <Hamburger size={24}/>
                </StyledNavbarToggler>
                <ClientBrand className="me-4"/>
            </Flex>

            {!isMd && <Flex className="d-md-none align-items-center">
                {actions}
            </Flex>}
        </Flex>

        <Collapse isOpen={!collapsed} navbar={true} className="navbar-collapse justify-content-end">
            {children}
        </Collapse>
    </Navbar>
};

export default Header;
