import {useContext, useState} from "react";
import moment from "moment";
import {useQuery} from "@apollo/client";
import {NOTES_SEARCH} from "../queries";
import {UserContext} from "./index";
import {useLocation} from 'react-router-dom';
import ArticlesContext from "./ArticlesContext";

const SearchProvider = ({children}) => {
    const {user} = useContext(UserContext);

    const {search} = useLocation();
    const params = new URLSearchParams(search);

    const [filter, setFilter] = useState({
        query: params.get('query') || '',
        date: {
            from: params.get('from') || moment().subtract(1, 'd').format('YYYY-MM-DD'),
            to: params.get('to') || moment().format('YYYY-MM-DD'),
        },
        categories: params.get('categories')?.split(',').map(category => parseInt(category)) || [2, 3, 4, 5, 6, 53]
    });

    const results = useQuery(NOTES_SEARCH, {
        context: {
            headers: {
                Authorization: user.token
            }
        },
        variables: {
            offset: 0,
            limit: 30,
            ...filter,
            query: filter.query || '*',
            ...filter.date
        }
    });

    return <ArticlesContext.Provider value={{filter: {filter, setFilter}, results: {
        ...results,
        data: results.data?.articles
    }}}>
        {children}
    </ArticlesContext.Provider>

}

export default SearchProvider;
