import React from 'react';
import {Flex} from "../Atoms";
import styled from 'styled-components';
import {Card} from "../Molecules";

const StyledProducts = styled(Flex).attrs({className: 'mb-4'})`
    overflow: auto;
`;

const ProductsWrapper = styled(Flex).attrs({className: 'ms-n2'})`
    min-width: auto;
`;

const StyledCard = styled(Card).attrs({className: 'm-2 my-3'})`
`;

const CardList = ({items}) => (
    <StyledProducts>
        <ProductsWrapper>
            {items.map(item => item.active && <StyledCard {...item}/>)}
        </ProductsWrapper>
    </StyledProducts>
)

export default CardList;
