import * as React from 'react';
import {Card, CardBody, CardFooter, CardImg, CardText, CardTitle} from "reactstrap";
import {colors} from "../../config/theme";
import styled from 'styled-components';
import Ticket from "../Atoms/Icons/Ticket";
import {Big} from "../Atoms";

const StyledCard = styled(Card)`
    ${props => `background-color: ${props.backgroundColor} !important;`}
    border-radius: 4px;
    border: 0 !important;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15) !important;
    width: 181px;
`;

const StyledCardImg = styled(CardImg)`
    background-color: white !important;
    height: 121px;
    object-fit: cover;
`;

const StyledCardTitle = styled(CardTitle)`
    color: ${props => props.color || colors.black};
    height: 4.3rem;
`;

const StyledCardText = styled(CardText)`
    max-height: 4.2rem;
    overflow: hidden;
    color: ${colors.black};
`;

const StyledCardFooter = styled(CardFooter)`
    background-color: transparent !important;
    border-top: 0 !important;
    color: ${colors.white}
`;

const CardItem = ({title, img, content, color, ...rest}) => (
    <StyledCard {...rest}>
        <StyledCardImg src={img}/>
        <CardBody className="pb-2">
            <StyledCardTitle color={color} tag="h2">{title}</StyledCardTitle>
            <StyledCardText><Big>{content}</Big></StyledCardText>
        </CardBody>
        <StyledCardFooter className="d-flex align-items-center pb-3">
            <Ticket size={24} color={colors.white} fill={colors.white}/>
            <span className="ms-3">asdfasd</span>
        </StyledCardFooter>
    </StyledCard>
);

export default CardItem;
