import React, {useContext} from 'react';
import {ArticleListItem} from "../../Organisms";
import {Flex} from "../../Atoms";
import {ArticlesContext} from "../../../context";
import {Button} from "reactstrap";

const ArticleList = ({onSelect}) => {
    const {results: {data, fetchMore}} = useContext(ArticlesContext);

    return <Flex flexDirection="column" flexShrink={0}>
        <Flex flexDirection="column" className="mb-3">
            {data?.data.map(article => (
                <ArticleListItem key={article.id}
                                 id={article.id}
                                 title={-1 !== article.title.indexOf(':') ? article.title.split(':')[1] : article.title}
                                 categories={article.categories}
                                 badge={-1 !== article.title.indexOf(':') ? article.title.split(':')[0] : null}
                                 date={article.date}
                                 onClick={() => {
                                     onSelect(article);
                                     document.getElementById('article-detail')?.scrollTo({top: 0, behavior: 'smooth' })
                                 }}
                />
            ))}
        </Flex>
        {(data?.data.length < data?.meta.count) && <Button color="primary" className="align-self-center mb-3" onClick={() => fetchMore({
            variables: {
                offset: data?.data.length
            }
        })}>Načítať ďalšie</Button>}
    </Flex>
}

export default ArticleList;
