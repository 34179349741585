import {ApolloClient, InMemoryCache} from "@apollo/client";
import {NOTES_URL_AUTHORIZED} from "../config/config";

const client = new ApolloClient({
    uri: NOTES_URL_AUTHORIZED,
    headers: {
        Authorization: localStorage.getItem('authorization') ? `Bearer ${localStorage.getItem('authorization')}` : ''
    },
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    articles: {
                        keyArgs: [
                            'query',
                            'from',
                            'to',
                            'categories',
                        ],
                        merge(existing, incoming, args) {
                            const merged = existing?.data.slice(0, incoming.meta.offset) || [];
                            for (let i = 0; i < incoming.data.length; ++i) {
                                merged[incoming.meta.offset + i] = incoming.data[i];
                            }
                            return {
                                ...incoming,
                                data: merged
                            };
                        }
                    }
                }
            }
        }
    })
});

export default client;
