import {gql} from '@apollo/client';

export default gql`mutation UpdateArticle(
    $id: String!, 
    $flag: Int, 
    $rating: Int, 
    $monitoringDate: String,
    $tags: [Int!]
) {
    updateArticle(
        id: $id,
        input: {
            flag: $flag,
            rating: $rating,
            monitoringDate: $monitoringDate,
            tags: $tags
        }
    ) {
        id
        title
        flag
        rating
        content
        source {
            name
            readability
        }
        service
        author
        page
        date
        score
        aveValue
        articlesTags {
            tag {
                id
                value
            }
        }
    }
}`