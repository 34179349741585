import {gql} from '@apollo/client';

export default gql`query Search(
    $query: String!, 
    $from: String!, 
    $to: String!, 
    $type: DateTypes, 
    $source: [Int!], 
    $offset: Int, 
    $limit: Int, 
    $orderBy: SearchOrderFields, 
    $direction: SearchOrderDirections
) {
    search (
        query: $query, 
        from: $from, 
        to: $to, 
        type: $type, 
        source: $source, 
        offset: $offset, 
        limit: $limit, 
        orderBy: $orderBy, 
        direction: $direction
    ) {
        data {
            id
            verityId
            title
            subtitle
            author
            service
            category
            keywords
            source {
                id
                name
                grp

            }
            date
            importedAt
            publishedAt
            lang
            content
            year
            issue
            page
            moderator
            visitor
            offset
            duration
            url
            priority
            measures
            ave

            score
            clippings {
                id
                url

            }
        }
        meta {
            count
            offset
            limit
        }
    }
}`