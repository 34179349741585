import * as React from 'react';
import {Formik, Form, Field} from 'formik';
import {withRouter} from 'react-router-dom';

import {Button, Container, Row, Col} from "reactstrap";
import logo from '../../assets/logo.png';
import {useMutation} from "@apollo/client";
import {LOGIN} from "../../queries";
import {FormikInput} from "../../components/Molecules";
import {ssoClient} from "../../clients";

const Login = ({history}) => {
    const [login] = useMutation(LOGIN, {
        client: ssoClient
    });

    return (
        <Container className="my-5">
            <Row>
                <Col md={6} className="mx-auto my-5">
                    <img alt="logo" src={logo}/>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mx-auto">
                    <Formik initialValues={{}} onSubmit={values => (
                        login({
                            variables: {
                                username: values.username.trim(),
                                password: values.password.trim()
                            }
                        })
                            .then(response => {
                                localStorage.setItem('authorization', response.data.login)
                                history.push('/dashboard')
                            })
                    )}>
                        <Form>
                            <Row>
                                <Col className="my-5">
                                    <Field id="username" name="username" component={FormikInput} label="Username"/>
                                    <Field id="password" type="password" name="password" component={FormikInput} label="Password"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="my-5 text-right">
                                    <Button color="primary">Login</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Formik>
                </Col>
            </Row>
        </Container>
    )
}

export default withRouter(Login);
