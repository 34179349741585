import styled from "styled-components";
import {Badge} from "reactstrap";
import {colors} from "../../config/theme";

const StyledBadge = styled(Badge).attrs({className: 'm-2 p-2'})`
    background-color: ${props => props.color || colors.silver};
    color: ${colors.white} !important;
    text-decoration: none;
    padding-top: .3rem;
    padding-bottom: .3rem;
    cursor: pointer;
`;

export default StyledBadge;
