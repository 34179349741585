import React, {useContext, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Flex} from "../../Atoms";
import styled from 'styled-components';
import PhotoContext from "../../../context/PhotoContext";

const FotoWrapper = styled(Flex)`
    overflow-x: scroll;
`;

const FotoInnerWrapper = styled(Flex)`
    flex: 0 0 auto;
`;

const StyledImg = styled.img`
    height: 115px;
    
    & + img {
        margin-left: 1rem;
    }
`;

const getFilename = (photo) => {
    if ('SITA' === photo.type) {
        return photo.filename.substr(0, 2) + '/' + photo.filename;
    }
    return photo.photoId + '.jpg';
}

const PhotoSearch = ({keywords, from, to, onClick}) => {
    const {photos} = useContext(PhotoContext);

    const [selected, setSelected] = useState(null);

    if (!photos.length) {
        return null
    }

    return (
        <Flex id="photo-search" flexDirection="column">
            <h2>Súvisiace foto</h2>
            {/*<a href={`http://foto-new2.sita.sk/#/auth?token=${localStorage.getItem('authorization')}`}*/}
            {/*   target="_blank"*/}
            {/*   rel="noreferrer"*/}
            {/*>Login</a>*/}
            <FotoWrapper>
                <FotoInnerWrapper>

                    {photos.map(photo => (
                        <StyledImg key={photo.id}
                                   onClick={() => setSelected(photo)}
                                   src={`https://foto-new.sita.sk/media/cache/resolve/medium/${photo.directory}/${getFilename(photo)}`}
                                   alt={photo.name}
                        />
                    ))}

                    {selected && <Modal isOpen={true} toggle={() => setSelected(null)} size="xl">
                        <ModalHeader toggle={() => setSelected(null)}>
                            <h2>{selected.name}</h2>
                        </ModalHeader>
                        <ModalBody>
                            <p>{selected.content}</p>
                            <p><a href="https://foto.sita.sk" target="_blank" rel="noreferrer">https://foto.sita.sk</a></p>
                            <p>Budeme radi ak si objednáte prístup do fotoservisu SITA na <a href="mailto:obchod@sita.sk">obchod@sita.sk</a></p>
                            <img src={`https://foto-new.sita.sk/media/cache/resolve/full/${selected.directory}/${getFilename(selected)}`}
                                 alt={selected.name}
                                 className="img-fluid"
                            />

                        </ModalBody>
                    </Modal>}

                </FotoInnerWrapper>
            </FotoWrapper>
        </Flex>
    )
};

export default PhotoSearch;
