import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Flex} from "../../Atoms";
import moment from 'moment';
import {colors} from "../../../config/theme";
import styled from 'styled-components';
import {Link, useHistory} from "react-router-dom";

const StyledArticleItem = styled(Flex)`
    background-color: ${props => props.selected ? colors.scooter : 'transparent'};
    border-radius: ${props => props.selected ? '4px' : 0};
    cursor: pointer;
    ${props => !props.selected && `border-bottom: 1px solid ${colors.mercury}`};
    
    color: ${props => props.selected ? colors.white : colors.black};
`;

const StyledLink = styled(Link)`
    color: ${colors.black};
    text-decoration: none;
    font-weight: 400;
    
    &:visited {
        color: ${colors.boulder};
    }
`;

const StyledTime = styled.span`
    color: ${props => props.selected ? colors.white : colors.boulder};
`;

const ArticleListItem = ({id, title, date, badge, selected, categories, onClick}) => {
    const history = useHistory();

    return <StyledArticleItem
        selected={selected}
        flexDirection="column"
        flexShrink={0}
        className="p-2"
        onClick={() => {
            history.push(`${-1 !== history.location.pathname.indexOf('/dashboard') ? '/dashboard' : '/favorites'}/${id}`);
            onClick();
        }}
    >
        <Flex alignItems="center" className="mb-2">
            <StyledTime>{moment(date).format('DD. MM. YYYY HH:mm')}</StyledTime>
            {badge && (
                <Badge
                    color={`#${categories?.[0]?.color || categories?.[0]?.parent?.color}`}
                    className="ml-3"
                >{badge}</Badge>
            )}
        </Flex>
        <Flex flexDirection="column">
            <h6>
                <StyledLink
                    to={`${-1 !== history.location.pathname.indexOf('/dashboard') ? '/dashboard' : '/favorites'}/${id}`}
                    onClick={e => e.preventDefault()} dangerouslySetInnerHTML={{__html: title}}
                />
            </h6>
        </Flex>
    </StyledArticleItem>
}

ArticleListItem.propTypes = {
    title: PropTypes.string.isRequired,
    badge: PropTypes.string,
    selected: PropTypes.bool,
    onClick: PropTypes.func
};

ArticleListItem.defaultProps = {
    selected: false
};

export default ArticleListItem;
