import React, {useContext} from "react";
import {ArticlesContext} from "../../../context";

const ResultsCount = () => {
    const {results: {data}} = useContext(ArticlesContext);

    return <p className="h3">{data?.meta.count} výsledkov</p>
}

export default ResultsCount;
