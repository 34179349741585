import * as React from 'react';
import {ProductJumbotron} from "../../Organisms";
import {Container} from "reactstrap";
import {colors} from "../../../config/theme";

import agenturneSpravyImg from "../../../assets/img/agenturne-spravy.png";
import monitoringMediiImg from "../../../assets/img/monitoring-medii.png";
import fotoImg from "../../../assets/img/foto.png";
import searchInImg from "../../../assets/img/search-in.png";
import audioImg from "../../../assets/img/audio.png";
import firemneInformacieImg from "../../../assets/img/firemne-informacie.png";
import webnovinyImg from "../../../assets/img/webnoviny.png";
import odborneObsahovePortalyImg from "../../../assets/img/odborne-obsahove-portaly.png";
import marketingoveSluzbyImg from "../../../assets/img/marketingove-sluzby.png";
import {Flex} from "../../Atoms";
import styled from 'styled-components';

const StyledProductList = styled(Flex)`
    overflow: auto;
`;

const StyledProductsWrapper = styled(Flex).attrs({className: 'ms-n3 py-3'})`
    min-width: auto;
`;

const ProductList = () => {
    let products = [
        {
            color: colors.yellowOrange,
            img: searchInImg,
            title: 'SITA Insider',
            content: 'Rýchle a presné spravodajstvo SITA zamerané na kľúčové témy hospodárskeho, politického, spoločenského a športového diania. Správy sú obsahovo previazané na archív SITA a ďalšie služby - fotobanku SITA, monitoring médií, databázu profilov osobností a údajov z verejných registrov.'
        },
        {
            color: colors.flamingo,
            img: fotoImg,
            title: 'Fotobanka SITA',
            content: 'Obrazová spravodajská databáza. Služba poskytuje vlastné autorské fotografie, ktoré vytvára tím interných fotografov, rastúca sieť regionálnych stringerov a fotoservis AP, ktorý denne prináša až 1 500 fotografií zo sveta. K dispozícii je archív od roku 2005, ale k vybraným udalostiam sú k dispozícii i staršie fotografie.'
        },
        {
            color: colors.froly,
            img: monitoringMediiImg,
            title: 'Monitoring médií',
            content: 'Poriadok v informačnom pretlaku. Výber informácií z viac ako 1500 zdrojov presne podľa vašich požiadaviek, ktoré pre vás pripravuje tím skúsených analytikov. Monitoring pre vás pripravujeme vo forme prehľadov posielaných e-mailom a dostupných v databáze Mediabase, alertov a k dispozícii je aj nástroj na samostatné vyhľadávanie Searchin.'
        },
        {
            color: colors.scooter,
            img: audioImg,
            title: 'Multimediálne služby',
            content: 'Pútavá a kreatívna forma prezentácie klienta. Video ponúka rozsiahle možnosti tvorby obsahu, ktorý má silný potenciál zaujať publikum a dosiahnuť výtlak na sociálnych médiách. Natívne video odporúčame kombinovať s natívnymi a PR článkami pre zvýšenie výslednej hodnoty a sily medializácie.'
        },
        {
            color: colors.jungleGreen,
            img: marketingoveSluzbyImg,
            title: 'PR komunikácia',
            content: 'SITA disponuje rozsiahlou sieťou na distribúciu PR obsahu cez online kanály a sociálne siete. Výhodou je vysoká škálovateľnosť a možnosť tematického zamerania. Radi vám pomôžeme aj s prípravou komunikácie. Skúsení copywriteri Vám pripravia kvalitné PR články z komerčných aj nekomerčných oblastí.'
        },
        {
            color: colors.lightningYellow,
            img: agenturneSpravyImg,
            title: 'SITA Biznis insider',
            content: 'Prepojte vašu spoločnosť so značkou SITA Biznis insider a prostredníctvom palety komunikačných nástrojov vyťažte maximum. Tento produkt znamená komplexné využitie všetkých informačných a komunikačných nástrojov, ako aj kanálov SITA s radom bonusových a individualizovaných služieb.'
        },
        {
            color: colors.lightningYellow,
            img: agenturneSpravyImg,
            title: 'SITA Briefly',
            content: 'Odvetvové a manažérske spravodajské prehľady, ktoré na dennej báze prinášajú starostlivo pripravený hutný prehľad informácií. Prehľady distribuujeme vždy ráno priamo do Vašej e-mailovej schránky. Time saver produkty navrhnuté pre manažérov, analytikov a konzultantov na naštartovanie pracovného dňa.'
        },
        {
            color: colors.tussock,
            img: webnovinyImg,
            title: 'Webnoviny',
            content: 'Portál s viac ako 17-ročnou tradíciou prinášajúci dôveryhodné, objektívne a nezávislé spravodajstvo z domova a zo sveta. Okrem aktuálneho spoločenského a politického diania prináša kultúrne témy, lifestyle, zábavu a informácie týkajúce sa zdravia. Pohybuje sa v prvej desiatke rebríčka návštevnosti IAB vo svojom segmente.'
        },
        {
            color: colors.citron,
            img: odborneObsahovePortalyImg,
            title: 'Odborné portály',
            content: 'Portfólio špecializovaných portálov so zameraním na priemysel, dopravu, energetiku, poľnohospodárstvo, financie, reality, školstvo, zdravie a zdravotníctvo. Fokus na hodnotný odborný obsah s jedinečnou cieľovou skupinou.'
        },
        {
            color: colors.crimson,
            img: firemneInformacieImg,
            title: 'Foaf.sk – firemné informácie',
            content: 'Databáza informácií o firmách, ľuďoch a vzťahoch. Foaf.sk integruje údaje z obchodného registra, registra účtovných závierok, sociálnej a zdravotných poisťovní a ďalších registrov. Jedinečný nástroj na vizualizáciu vzťahov medzi spoločnosťami a ich majiteľmi či štatutármi.'
        },
    ];

    return (
        <Container>
            <StyledProductList>
                <StyledProductsWrapper flex={{
                    lg: {flexDirection: 'column'}
                }}>
                    {products.map(item => <ProductJumbotron {...item} />)}
                </StyledProductsWrapper>
            </StyledProductList>
        </Container>
    );
}

export default ProductList;
