import {useHistory} from 'react-router-dom';
import {useMutation} from "@apollo/client";
import {LOGOUT} from "../../queries";
import {ssoClient} from "../../clients";
import {useContext, useEffect} from "react";
import {UserContext} from "../../context";

const Logout = () => {
    const {user} = useContext(UserContext);

    const history = useHistory();
    const [logout] = useMutation(LOGOUT, {
        client: ssoClient,
        context: {
            headers: {
                Authorization: user.token
            }
        },
        variables: {
            token: user.token
        }
    });

    useEffect(() => {
        logout().then(() => {
            localStorage.removeItem('authorization');
            history.push('/');
        });
    }, []);

    return null;
}

export default Logout;
