import {gql} from '@apollo/client';

export default gql`query Search(
    $query: String!, 
    $startAt: String!,
    $endAt: String!,
    $offset: Int = 0,
    $limit: Int = 10
) {
    search (
        query: $query, 
        startAt: $startAt, 
        endAt: $endAt,
        offset: $offset,
        limit: $limit
    ) {
        id
        type
        photoId
        name
        content
        directory
        filename
    }
}`
