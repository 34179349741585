import * as React from 'react';
import styled, {css} from "styled-components";
import moment from "moment";
import {Flex} from '../../Atoms';
import DateNames from './DateNames';
import Dates from './Dates';
import {aquamarineBlue, dustyGray, ebonyClay, paleGray} from "./variables";

const Year = styled.div.attrs({className: 'py-1 fw-bold'})`
    color: ${dustyGray};
    width: 20%;
    text-align: center;
    ${props => props.selected && `
        background-color: ${ebonyClay};
        color: white;
        border-radius: 6px;
    `};
`;

const StyledYears = styled(Flex)`
    flex-wrap: wrap;
`;

class Years extends React.PureComponent {

    handleChange = value => {
        const {date, onChange} = this.props;

        onChange(date.clone().year(value));
    };

    render() {
        const {date} = this.props;

        const years = [];
        for (let i = moment().format('YYYY'); i >= 1990; i--) {
            years[years.length] = i;
        }
        return <StyledYears>
            {years.map(year => (
                <Year
                    key={year}
                    selected={date.isSame(moment(year), 'year')}
                    onClick={() => this.handleChange(year)}
                >
                    {year}
                </Year>
            ))}
        </StyledYears>
    }
}

const Month = styled.div.attrs({className: 'py-4'})`
    font-size: .875rem;
    color: ${dustyGray};
    width: 25%;
    text-align: center;
    ${props => props.selected && `
        background-color: ${ebonyClay};
        color: white;
        border-radius: 6px;
    `};
`;

const StyledMonths = styled(Flex).attrs({className: 'my-4'})`
    flex-wrap: wrap;
`;

class Months extends React.PureComponent {

    handleChange = value => {
        const {date, onChange} = this.props;

        onChange(date.clone().month(value));
    };

    render() {
        const {date} = this.props;

        const months = [];
        for (let i = 1; i <= 12; i++) {
            months[months.length] = i;
        }
        return <StyledMonths>
            {months.map(month => (
                <Month
                    key={month}
                    selected={date.isSame(moment(month, 'MM'), 'month')}
                    onClick={() => this.handleChange(month)}
                >
                    {moment().month(month).format('MMM')}
                </Month>
            ))}
        </StyledMonths>
    }
}

const StyledDatePicker = styled.div`
    width: 320px;
`;

const datePartActiveMixin = css`
    border-bottom: 1px solid ${aquamarineBlue} 
`;

const DatePart = styled(({active, ...rest}) => <div {...rest} />).attrs({className: 'px-3'})`
    font-weight: 600;
    ${props => props.active && datePartActiveMixin};
`;

const Label = styled.div`
    font-size: .875rem;
`;

const Date = styled(Flex).attrs({className: 'my-3'})`
    font-size: 1.1rem;
    border-bottom: 1px solid ${paleGray}
`;

class DatePicker extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            mode: 'date',
            hover: null
        }
    }

    handleSetMode = mode => {
        this.setState({mode: mode})
    };

    handleHover = date => {
        this.setState({ hover: date })
    };

    handleChangeYear = date => {
        this.handleChange(date);

        this.setState({mode: 'month'});
    };

    handleChangeMonth = date => {
        this.handleChange(date);

        this.setState({mode: 'date'});
    };

    handleChange = date => {
        const {onChange} = this.props;

        onChange(date);
    };

    render() {
        const {date, from, to, label} = this.props;

        return <StyledDatePicker>
            <Label>{label}</Label>
            <Date>
                <DatePart active={'date' === this.state.mode} onClick={() => this.handleSetMode('date')}>{date.format('DD')}</DatePart>/
                <DatePart active={'month' === this.state.mode} onClick={() => this.handleSetMode('month')}>{date.format('MMM')}</DatePart>/
                <DatePart active={'year' === this.state.mode} onClick={() => this.handleSetMode('year')}>{date.format('YYYY')}</DatePart>
            </Date>
            {'year' === this.state.mode && <Years date={date} onChange={this.handleChangeYear}/>}
            {'month' === this.state.mode && <Months date={date} onChange={this.handleChangeMonth}/>}
            {'date' === this.state.mode && <DateNames/>}
            {'date' === this.state.mode && <Dates active={date}
                                                  from={from}
                                                  to={to}
                                                  hover={this.state.hover}
                                                  onChange={this.handleChange}
                                                  onHover={this.handleHover}
                                                  expanded={true}
            />}
        </StyledDatePicker>
    }
}

export default DatePicker;
