import React, {useState} from "react";
import {Big, Flex} from "../../Atoms";
import styled from 'styled-components';
import {colors} from "../../../config/theme";
import Basket from "../../Atoms/Icons/Basket";
import LoginForm from "./../LoginForm";
import Author from "../../Atoms/Icons/Author";
import {Nav, NavItem, NavLink, Tooltip} from "reactstrap";
import {Header as Navigation} from '../';
import {useMediaQuery} from "react-responsive/src";
import {md} from "../../../responsive";
import {Link} from "react-router-dom";

const StyledTooltip = styled(Tooltip)`
    
    .tooltip-inner {
        text-align: left;
        padding: 1rem;
        max-width: auto !important;
    }
    
    .form-control {
        width: auto !important;
    }
`

const StyledLogin = styled.span`
    color: ${props => props.active ? colors.froly : colors.white};
`;

const StyledLoginWrapper = styled(Flex)`
    cursor: pointer;
`;

const Login = () => {
    const [isLoginOpen, setIsLoginOpen] = useState(false);

    const isLg = useMediaQuery(md);

    return (
        <>
            <StyledLoginWrapper id="login" onClick={() => setIsLoginOpen(!isLoginOpen)} className="mx-2" alignItems="center">
                <Author size={20} color={isLoginOpen ? colors.froly : colors.white} className="me-2"/>
                {isLg && <StyledLogin active={isLoginOpen} className="ml-2">Prihlásenie</StyledLogin>}
            </StyledLoginWrapper>
            <StyledTooltip target="login" isOpen={isLoginOpen}>
                <LoginForm/>
            </StyledTooltip>
        </>
    )
}

const StyledNavItem = styled(NavItem).attrs({className: 'px-1'})`
    border-left: 1px solid ${colors.boulder}
`;

const StyledNavLink = styled(NavLink)`
    color: ${colors.white};
    text-decoration: none;
    
    &:hover {
        text-decoration: underline !important;
    }
`

const Header = () => {

    const isMd = useMediaQuery(md);

    return <Navigation actions={
        <>
            <Login/>
            <Basket size={20} color={colors.white} className="mx-2"/>
        </>
    }>
        {isMd && (
            <Flex flex={1} className="justify-content-between">
                <Nav navbar={true}>
                    <StyledNavItem>
                        <StyledNavLink tag="a" href="https://biz.sita.sk/o-nas/"><Big>O SITA</Big></StyledNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                        <StyledNavLink tag="a" href="https://biz.sita.sk/#sluzby"><Big>Služby</Big></StyledNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                        <StyledNavLink tag="a" href="https://biz.sita.sk/o-nas/#kontakty"><Big>Kontakty</Big></StyledNavLink>
                    </StyledNavItem>
                </Nav>

                <Nav navbar={true} className="align-items-center">
                    <Login/>
                    <Basket size={24} color={colors.white} className="mx-2"/>
                </Nav>
            </Flex>
        )}
    </Navigation>
};

export default Header;
