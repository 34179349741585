import {useContext, useState} from "react";
import moment from "moment";
import {useQuery} from "@apollo/client";
import {NOTES_GET_FAVORITES} from "../queries";
import {UserContext} from "./index";
import {useLocation} from 'react-router-dom';
import ArticlesContext from "./ArticlesContext";

const FavoritesProvider = ({children}) => {
    const {user} = useContext(UserContext);

    const {search} = useLocation();
    const params = new URLSearchParams(search);

    const [filter, setFilter] = useState({
        query: params.get('query') || '',
        date: {
            from: moment().subtract(1, 'd').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
        },
        categories: [2, 3, 4, 5, 6, 53]
    });

    const results = useQuery(NOTES_GET_FAVORITES, {
        context: {
            headers: {
                Authorization: user.token
            },
        },
        fetchPolicy: 'network-only',
        variables: {
            offset: 0,
            limit: 30,
            ...filter,
            query: filter.query || '*',
            ...filter.date
        }
    });

    return <ArticlesContext.Provider value={{filter: {filter, setFilter}, results: {
        ...results,
        data: results.data?.favorites
    }}}>
        {children}
    </ArticlesContext.Provider>
}

export default FavoritesProvider;
