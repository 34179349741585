import React, {useContext, useState} from 'react';
import {SEARCH} from "../../../queries";
import {useQuery} from "@apollo/client";
import {ArticlesCard} from "../../Organisms";
import {UserContext} from "../../../context";
import {searchClient} from "../../../clients";
import {ArticleModal} from "../../index";
import {ShowMore} from "../../Molecules";
import moment from "moment";

const RelatedSearch = ({keywords, persons, companies, from, to, onClick}) => {
    const {user} = useContext(UserContext);
    const [selected, setSelected] = useState(null);

    let query = `"${keywords.join('", "')}"`;

    if (0 !== persons.length) {
        query = `("${persons.join('", "')}")^3 ` + query;
    }

    if (0 !== companies.length) {
        query += `("${companies.join('", "')}")^3 ` + query;
    }

    let variables = {
        query: query,
        from: from,
        to: to,
        source: [5990,
            1906, 677, 722, 6084, 1935, 6086, 634, // sme
            6008, 750, //dennikn
            6112, 6109, 76, 6052, 681, 2019, 679, 6028,
            16, 19, 2026, 18, 15//joj, stv1, markiza, ta3, slovensky rozhlas
        ], // sources by ticket #13982
        limit: 5
    };

    const {data} = useQuery(SEARCH, {
        client: searchClient,
        context: {
            headers: {
                Authorization: user.token
            }
        },
        variables: variables
    });

    const url = new URLSearchParams({
        query: variables.query,
        from: variables.from,
        to: variables.to
    })

    return (
        <>
            <ArticlesCard
                id="searchin"
                title="Súvisiace správy z medií"
                onSelect={id => setSelected(data?.search.data.find(article => article.id === id))}
                withBadge={false}
                articles={data?.search.data.map(article => ({
                    id: article.id,
                    title: article.title,
                    info: `${article.source.name}, ${moment(article.date).format('DD. MM. YYYY HH:mm')}, ${article.service}`
                }))}
                more={<a href={`https://www.searchin.sk/auth/${user.token}?${url.toString()}`} target="_blank" rel="noreferrer">
                    <ShowMore/>
                </a>}
            />
            {selected && (
                <ArticleModal
                    title={selected.title}
                    content={selected.content}
                    source={selected.source.name}
                    date={selected.date}
                    service={selected.service}
                    author={selected.author}
                    clipping={selected.clippings[0].url}
                    onClose={() => setSelected(null)}
                />
            )}
        </>
    )
};

export default RelatedSearch;
