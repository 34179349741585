
const sm = {minDeviceWidth: 576};
const md = {minDeviceWidth: 768};
const lg = {minDeviceWidth: 992};
const xl = {minDeviceWidth: 1200};

export {
    sm,
    md,
    lg,
    xl
}