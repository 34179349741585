import * as React from 'react';
import styled from 'styled-components';
import {Button} from "reactstrap";
import {Jumbotron} from "../Molecules";
import avatar from "../../assets/img/male.png";
import CardList from "./CardList";
import {colors} from "../../config/theme";

const StyledSecondaryTitle = styled.h1`
    font-weight: 300;
`

const member = {
    active: true,
    backgroundColor: colors.lightningYellow,
    color: colors.white,
    img: avatar,
    title: 'SITA insider',
    content: 'Získajte lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, <strong>quis nostrud exercitation</strong> ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
};

const TeamJumbotron = ({color, secondaryColor, reverse = false}) => (
    <Jumbotron color={color} secondaryColor={secondaryColor} reverse={reverse} children1={
        <>
            <StyledSecondaryTitle>predplatné<br/><strong>čosi</strong></StyledSecondaryTitle>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,  quis nostrud exercitation  ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>
            <Button color="secondary">VIAC INFO</Button>
        </>
    } children2={
        <>
            <CardList items={Array(4).fill(member, 0)}/>
        </>
    }>
    </Jumbotron>
);

export default TeamJumbotron;
