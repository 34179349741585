//@flow

import * as React from 'react';
import {Modal, ModalHeader, ModalBody} from "reactstrap";

const QuickModal = ({title, children, onClose, ...rest}) => {

    return (
        <Modal isOpen={true} toggle={onClose} {...rest}>
            <ModalHeader toggle={onClose}>
                <span dangerouslySetInnerHTML={{__html: title}}/>
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
        </Modal>
    )
}

export default QuickModal;
