import * as React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

import FastNavigation from './FastNavigation';
import DatePicker from './DatePicker';
import {paleGray} from './variables';
import {Flex, FormikInput, Hr} from "../../Atoms";
import {useState} from "react";
import {Label} from "reactstrap";
import moment from "moment";

const Divider = styled.div.attrs({className: 'mx-3'})`
    border-right: 1px solid ${paleGray}
`;

const StyledRangeDatePicker = styled(Flex).attrs({className: 'py-2'})`
    flex-direction: column;
    
    @media only screen and (min-width: 768px) {
        flex-direction: row;
    }
`;

const RangeDatePicker = ({from, to, onChange}) => {
    const [date, setDate] = useState({
        from: from,
        to: to
    });

    const handleFromChange = value => {
        setDate({
            ...date,
            from: value
        });
        onChange({
            from: value,
            to: date.to
        });
    };

    const handleToChange = value => {
        setDate({
            ...date,
            to: value
        });
        onChange({
            from: date.from,
            to: value
        });
    };

    const handleFastChange = (from, to) => {
        const value = {
            from: from,
            to: to
        };

        setDate(value);
        onChange(value);
    };

    return <>
        <StyledRangeDatePicker>
            <MediaQuery minWidth={768}>
                <DatePicker label="Dátum od" date={date.from} from={date.from} to={date.to} onChange={handleFromChange}/>
                <Divider/>
                <DatePicker label="Dátum do" date={date.to} from={date.from} to={date.to} onChange={handleToChange}/>
                <Divider/>
            </MediaQuery>
            <MediaQuery maxWidth={767}>
                <Label>Dátum od</Label>
                <FormikInput name="from" type="date" onChange={props => handleFromChange(moment(props.target.value))} value={date.from.format('YYYY-MM-DD')}/>
                <Label>Dátum do</Label>
                <FormikInput name="to" type="date" onChange={props => handleToChange(moment(props.target.value))} value={date.to.format('YYYY-MM-DD')}/>
                <Hr />
            </MediaQuery>
            <FastNavigation onChange={handleFastChange}/>
        </StyledRangeDatePicker>
    </>
}

    // componentWillUnmount() {
    //     if (!datestate.to) {
    //         this.handleClick(this.state.from);
    //     }
    // }

export default RangeDatePicker;
