import * as React from 'react';
import {Button, Card} from "reactstrap";
import {colors} from "../../config/theme";
import styled from 'styled-components';
import Ticket from "../Atoms/Icons/Ticket";
import {Big, Flex} from "../Atoms";

const StyledJumbotron = styled(Card)`
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15) !important;
    width: 295px;
    
    @media (min-width: 992px) {
        width: auto;
    }
    
`;

const StyledCardTitle = styled.h1`
    font-size: 3rem;
    color: ${props => props.color};
`;

const StyledContent = styled.p`
    height: 5.5rem;
    overflow: hidden;
    font-size: 14px;
`

const StyledOrderButton = styled(Button)`
    background-color: ${props => props.color};
    color: ${colors.white};
`

const StyledInfo = styled(Flex)`
    @media (min-width: 992px) {
        width: 150px;
    }
`

const ProductImg = styled.img`
    width: 100%;
    
    @media (min-width: 992px) {
        width: 250px;
    }
`;

const ProductJumbotron = ({color, img, title, content}) => (
    <StyledJumbotron className="my-3 ms-3" color={color}>
        <Flex className="flex-column flex-lg-row">
            <Flex>
                <ProductImg src={img} className="img-fluid"/>
            </Flex>
            <Flex flex={1} className="p-4" flexDirection="column">
                <Flex flexDirection="column">
                    <StyledCardTitle color={color} dangerouslySetInnerHTML={{__html: title}}/>
                    <StyledContent dangerouslySetInnerHTML={{__html: content}}/>
                </Flex>
                <Flex className="d-none d-lg-flex">
                    <StyledOrderButton className="px-5" color={color}><Big>OBJEDNAŤ</Big></StyledOrderButton>
                    <p><Ticket color={colors.white} size={24}/>45&euro;/mesiac</p>
                </Flex>
            </Flex>
            <StyledInfo className="p-4 ps-lg-4 flex-lg-column" justifyContent="center">
                <Flex flex={1} className="flex-wrap flex-lg-nowrap flex-column align-items-start">
                    <h2>1 700</h2>
                    <span>MEDIÍ</span>
                </Flex>
                <Flex flex={1} className="flex-wrap flex-lg-nowrap flex-column align-items-start">
                    <h2>17 000</h2>
                    <span>ČLÁNKOV</span>
                </Flex>
                <Flex flex={1} className="flex-wrap flex-lg-nowrap flex-column align-items-start">
                    <h2>124</h2>
                    <span>ZAMESTNANCOV</span>
                </Flex>
            </StyledInfo>
            <Flex className="p-4 d-lg-none">
                <StyledOrderButton className="px-5" color={color}><Big>OBJEDNAŤ</Big></StyledOrderButton>
                <p><Ticket color={colors.white} size={24}/>45&euro;/mesiac</p>
            </Flex>

        </Flex>
    </StyledJumbotron>
);

export default ProductJumbotron;
