import * as React from 'react';
import { DateCell } from './Cell';

class Date extends React.PureComponent {

    get first() {
        const { date, from, to, hover } = this.props;

        return (to && date.isSame(from, 'day'))
            || (!to && hover && hover.isSameOrBefore(from, 'day') && date.isSame(hover, 'day'))
            || (!to && hover && hover.isAfter(from, 'day') && date.isSame(from, 'day'))
            ;
    }

    get last() {
        const { date, from, to, hover } = this.props;

        return (to && date.isSame(to, 'day'))
            || (!to && hover && hover.isSameOrAfter(from, 'day') && date.isSame(hover, 'day'))
            || (!to && hover && hover.isBefore(from, 'day') && date.isSame(from, 'day'))
            ;
    }

    get selected() {
        const { date, from, to } = this.props;

        return (date.isSameOrAfter(from, 'day') && date.isSameOrBefore(to, 'day')) || (date.isSame(from, 'day') && !to);
    }

    get today() {
        const { date, active } = this.props;

        return date.isSame(active, 'day');
    }

    get hover() {
        const { date, from, to, hover } = this.props;

        return !to && (
            (hover && hover.isBefore(from, 'day') && date.isSameOrAfter(hover, 'day') && date.isSameOrBefore(from, 'day'))
            || (hover && hover.isAfter(from, 'day') && date.isSameOrBefore(hover, 'day') && date.isSameOrAfter(from, 'day'))
        );
    }

    get other() {
        const { date, active } = this.props;

        return date.isBefore(active.clone().startOf('month'), 'day') || date.isAfter(active.clone().endOf('month'), 'day');
    }

    get mark() {
        const { marks = [], date } = this.props;

        return -1 !== marks.indexOf(date.format('YYYY-MM-DD'));
    }

    handleClick = (e) => {
        const { date, onChange } = this.props;

        onChange(date);
    };

    handleHover = () => {
        const { date, onHover } = this.props;

        onHover(date);
    };

    render() {
        const { date } = this.props;

        return <DateCell
                     first={this.first}
                     last={this.last}
                     hover={this.hover}
                     selected={this.selected}
                     today={this.today}
                     otherMonth={this.other}
                     mark={this.mark}
                     onClick={this.handleClick}
                     onMouseEnter={this.handleHover}
        >{date.format('D')}</DateCell>
    }
}

export default Date