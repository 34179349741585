import React, {useState} from 'react';
import moment from 'moment';
import {Button, Input, Modal, ModalBody, Tooltip} from "reactstrap";
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

import DatePicker from './DatePicker';
import {Flex, FormikInput} from "../Atoms";
import {useField} from "formik";
import {colors} from "../../config/theme";

const StyledFooter = styled(Flex)`
    justify-content: flex-end;
`;

const StyledTooltip = styled(Tooltip)`
    .tooltip-inner {
        padding: 1rem;
        color: ${colors.black};
        background-color: ${colors.white};
        border: 1px solid ${colors.black};
        border-radius: 0;
        max-width: none;
    }
`;

const DatePickerInput = (props) => {
    const [
        {value, ...field},
        {touched, error, ...rest},
        {setValue}
    ] = useField(props);

    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => setIsOpen(!isOpen);

    const getFrom = () => {
        return value.from && moment(value.from);
    }

    const getTo = () => {
        return value.to && moment(value.to);
    }

    const handleChange = value => {
        setValue({
            from: value.from.format('YYYY-MM-DD'),
            to: value.to ? value.to.format('YYYY-MM-DD') : null
        })
    };

    const getFooter = () => {
        return <StyledFooter flex={1}>
            <Button color="primary" onClick={handleToggle}>Potvrdiť</Button>
        </StyledFooter>
    }

    return <>
        <Input
            id="datepicker-button"
            onClick={handleToggle}
            value={`${getFrom().format('DD/MM/YYYY')} - ${getTo().format('DD/MM/YYYY')}`}
        >
            {/*<Icon type="calendar" size={14}/>*/}
        </Input>
        <MediaQuery minWidth={1200}>
            {isOpen && <StyledTooltip autohide={false} isOpen={isOpen} target="datepicker-button" tether={{
                offset: '-3px -5px',
                attachment: 'top right',
                targetAttachment: 'bottom right',
                constraints: []
            }}>
                <DatePicker from={getFrom()} to={getTo()} onChange={handleChange}/>
                <div className="m-2">
                    {getFooter()}
                </div>
            </StyledTooltip>}
        </MediaQuery>
        <MediaQuery maxWidth={1199}>
            {isOpen && <Modal isOpen={isOpen} toggle={handleToggle}>
                <ModalBody>
                    <DatePicker from={getFrom()} to={getTo()} onChange={handleChange}/>
                    <div className="m-2">
                        {getFooter()}
                    </div>
                </ModalBody>
            </Modal>}
        </MediaQuery>
    </>
}

export default DatePickerInput;
