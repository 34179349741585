import React, {useContext, useState} from 'react';
import maleImg from '../../assets/img/male.png';
import femaleImg from '../../assets/img/female.png';
import PropTypes from "prop-types";
import {UserContext} from "../../context";
import styled from 'styled-components';
import {Tooltip} from "reactstrap";

const StyledAvatar = styled.img`
    display: block;
`;

const Avatar = ({size}) => {
    const {user} = useContext(UserContext);
    const [isOpen, setIsOpen] = useState(false);

    return <>
        <StyledAvatar id="user-avatar" src={'f' === user?.sso.gender ? femaleImg : maleImg} alt="" width={size} height={size}/>
        <Tooltip isOpen={isOpen} target="user-avatar" toggle={() => setIsOpen(!isOpen)}>
            {user.sso.username}
        </Tooltip>
    </>
}

Avatar.propTypes = {
    size: PropTypes.number
}

Avatar.defaultProps = {
    size: 28
}

export default Avatar;
