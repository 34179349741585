import React from 'react';
import PropTypes from "prop-types";
import {Big} from "../Atoms";
import styled from 'styled-components';

const StyledStrong = styled.strong`
    cursor: pointer;
`;

const ShowMore = ({onClick}) => (
    <Big><StyledStrong onClick={onClick}>zobraziť viac</StyledStrong></Big>
);

ShowMore.propTypes = {
    onClick: PropTypes.func.isRequired
}

ShowMore.defaultProps = {
    onClick: () => {}
}

export default ShowMore;
