const SSO_URL = 'https://sso.sixmedia.sk/graphql/';
// const SSO_URL = 'http://192.168.0.102:8090/public/graphql/';
// const GRAPHQL_URL = 'https://admin.mediabase.sk/app_dev.php/graphql/';
// const GRAPHQL_URL = 'http://192.168.43.108:8080/web/app_dev.php/graphql/';
const GRAPHQL_URL = 'http://192.168.0.102:8080/web/app_dev.php/graphql/';
const SEARCH_URL = 'https://search.sita.sk/graphql/';
const NOTES_URL_ANONYMOUS = 'https://notes.sita.sk/graphql/graphql/anonymous';
const NOTES_URL_AUTHORIZED = 'https://notes.sita.sk/graphql/graphql/authorized';
// const NOTES_URL_ANONYMOUS = 'http://172.17.0.1:8080/web/app_dev.php/graphql/graphql/anonymous';
// const NOTES_URL_AUTHORIZED = 'http://172.17.0.1:8080/web/app_dev.php/graphql/graphql/authorized';
const ORSR_URL = 'https://orsr.sita.sk/graphql/';
// const PHOTO_URL = 'http://localhost:8080/graphql/';
const PHOTO_URL = 'https://foto-new.sita.sk/graphql/';

const WIKI_URL = 'https://sk.wikipedia.org/w/api.php';


const CLIPPING_URL_SMALL = 'https://search.sita.sk/media/cache/resolve/small/clippings/';
const CLIPPING_URL_MEDIUM = 'https://search.sita.sk/media/cache/resolve/medium/clippings/';
const CLIPPING_URL_LARGE = 'https://search.sita.sk/media/cache/resolve/large/clippings/';
const CLIPPING_URL = 'https://search.sita.sk/clippings/';

export {
    SSO_URL,
    GRAPHQL_URL,
    SEARCH_URL,
    NOTES_URL_ANONYMOUS,
    NOTES_URL_AUTHORIZED,
    PHOTO_URL,
    ORSR_URL,

    WIKI_URL,

    CLIPPING_URL,
    CLIPPING_URL_LARGE,
    CLIPPING_URL_MEDIUM,
    CLIPPING_URL_SMALL
}
