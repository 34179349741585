import React, {useState} from "react";
import {Container} from "reactstrap";
import services from "../../config/services";
import Service from "./Service";
import {EosIcon, Flex} from "../Atoms";
import {colors} from "../../config/theme";
import styled from 'styled-components';

const servicesArray = Array(5).fill(services[0], 0);

const StyledEosIcon = styled(EosIcon)`
    cursor: pointer;
`;

const Services = () => {
    const [active, setActive] = useState(null);

    return <Container>
        <Service service={services[0]} active={true}/>

        <Service service={{
            title: 'DOMÁCE SPRAVODAJSTVO',
            content: (
                <Flex flex={{
                    xs: {flexDirection: 'column'},
                    md: {flexDirection: 'row'}
                }}>
                    <Flex flexDirection="column" className="pe-4" flex={1}>
                        <h4>DOMÁCE SPRAVODAJSTVO</h4>
                        <p>Online databáza dostupná v reálnom čase cez internet. Domáci spoločensko-politický servis vysiela správy denne od cca 7.00 do 21.00, v prípade potreby nepretržite. Jeho spravodajský tím v priemere  denne pripraví 100 správ, vo víkendových dňoch vyše 40 správ. K dispozícii je archív od roku 1997.</p>
                    </Flex>
                    <Flex>
                        <img className="img-fluid" src="https://place-hold.it/800x300"/>
                    </Flex>
                </Flex>
            )
        }} active={true}/>


        {servicesArray.map((service, index) => <Flex key={index} alignItems="center" className="border-bottom mb-3">
            <Service service={service} active={active === index}/>
            {active !== index && <StyledEosIcon className="me-2" color={colors.boulder} onClick={() => setActive(index)}>keyboard_arrow_down</StyledEosIcon>}
        </Flex>)}
    </Container>
};

export default Services;
