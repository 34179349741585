import * as React from 'react';

import Date from './Date';
import Row from './Row';
import {Flex} from "../../Atoms";

class Week extends React.PureComponent {

    get dates() {
        const {week} = this.props;

        let dates = [];
        for (let i = 0, date = week.clone(); i < 7; i++, date.add(1, 'd')) {
            dates.push(date.clone());
        }

        return dates;
    }

    render() {
        const {week, ...rest} = this.props;

        return <Row>
            {this.dates.map((date, index) => <Date key={index} date={date} {...rest}/>)}
        </Row>
    }
}

const Dates = ({active, expanded, ...rest}) => {

    const start = () => {
        return active.clone().startOf('month').startOf('week');
    }

    const end = () => {
        return active.clone().endOf('month').endOf('week');
    }

    const weeks = () => {
        const weeks = [];
        for (let week = start(); week.isSameOrBefore(end()); week.add(1, 'w')) {
            if (expanded || (week.isSameOrBefore(active) && week.clone().add(1, 'w').isAfter(active))) {
                weeks.push(week.clone());
            }
        }

        return weeks;
    }

    return <Flex flex={1} flexDirection="column">
        {weeks().map((week, index) => {
            return <Week key={index} week={week} active={active} expanded={expanded} {...rest}/>
        })}
    </Flex>
}

export default Dates;
