import 'eos-icons/dist/css/_eos-icons.scss'
import './App.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import * as React from 'react';
import {Redirect, BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {ApolloProvider} from '@apollo/client';

import {Dashboard, Favorites} from "./components";
import Homepage from "./components/Pages/Homepage";
import {UserProvider} from "./context";
import {notesClient} from "./clients";
import Login from "./components/Pages/Login";
import Logout from "./components/Pages/Logout";
import About from "./components/Pages/About";
import Services from "./components/Pages/Services";
import BusinessInsiderPage from "./components/Pages/BusinessInsider";
import Auth from "./components/Pages/Auth";
import Protected from "./components/Templates/Protected";

function App() {
    return (
        <ApolloProvider client={notesClient} injectGlobal={true}>
            <Router>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/logout" render={props => {
                        if (!localStorage.getItem('authorization')) {
                            return props.history.push('/');
                        }

                        return <UserProvider value={{
                            token: localStorage.getItem('authorization')
                        }}>
                            <Protected>
                                <Logout/>
                            </Protected>
                        </UserProvider>
                    }}/>
                    <Route path="/auth/:token" component={Auth}/>
                    <Route path="/" render={props => {
                        return (
                            <Switch>
                                <Route path="/dashboard" render={props => {
                                    if (!localStorage.getItem('authorization')) {
                                        return props.history.push('/');
                                    }

                                    return <UserProvider value={{
                                        token: localStorage.getItem('authorization')
                                    }}>
                                        <Protected>
                                            <Dashboard/>
                                        </Protected>
                                    </UserProvider>
                                }}/>
                                <Route path="/favorites" render={props => {
                                    if (!localStorage.getItem('authorization')) {
                                        return props.history.push('/');
                                    }

                                    return <UserProvider value={{
                                        token: localStorage.getItem('authorization')
                                    }}>
                                        <Protected>
                                            <Favorites/>
                                        </Protected>
                                    </UserProvider>
                                }}/>
                                <Route path="/about" component={About}/>
                                <Route path="/services" component={Services}/>
                                <Route path="/business-insider" component={BusinessInsiderPage}/>
                                <Route path="/" component={Homepage}/>
                                <Redirect to="/"/>
                            </Switch>
                        );
                    }}/>
                </Switch>
            </Router>
        </ApolloProvider>
    );
}

export default App;
