import * as React from 'react';
import styled from "styled-components";
import {Flex} from "./Atoms";
import {colors} from "../config/theme";
import Author from "./Atoms/Icons/Author";
import {CLIPPING_URL_LARGE, CLIPPING_URL_MEDIUM,} from "../config/config";
import {useState} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import PropTypes from "prop-types";
import Category from "./Atoms/Icons/Category";
import Date from "./Atoms/Icons/Date";
import moment from "moment";

const StyledHeader = styled(Flex)`
    background-color: ${colors.scooter};
    color: white;
    border-radius: 4px;
`;

const ArticleModalArticleProps = {
    title: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    clipping: PropTypes.string
}

const propTypes = {
    ...ArticleModalArticleProps,

    onClose: PropTypes.func
};

const ArticleModal = ({title, source, author, clipping, date, content, service, onClose}) => {
    const [isClippingOpen, setIsClippingOpen] = useState(false);

    return (
        <>
            <Modal isOpen={true} toggle={onClose} size="lg">
                <ModalHeader toggle={onClose}>
                    <Flex>
                        {clipping && <img alt="" src={`${CLIPPING_URL_MEDIUM}/${clipping}`} onClick={() => setIsClippingOpen(true)}/>}
                        <h2 dangerouslySetInnerHTML={{__html: title}}/>
                    </Flex>
                </ModalHeader>
                <ModalBody>

                    <StyledHeader flexDirection="column" className="p-3 mb-4">
                        <Flex alignItems="center">
                            {source && <Flex alignItems="center" className="me-3">{source}</Flex>}
                            <Flex alignItems="center" className="me-3">
                                <Date size={16} fill={colors.white} className="me-2"/>
                                <span>{moment(date).format('DD. MMM YYYY HH:mm')}</span>
                            </Flex>
                            <Flex alignItems="center" className="me-3">
                                <Author size={16} fill={colors.white} className="me-2"/>
                                <span>{author}</span>
                            </Flex>
                            <Flex alignItems="center" className="me-3">
                                <Category size={16} className="me-2"/>
                                <span>{service}</span>
                            </Flex>
                        </Flex>
                    </StyledHeader>
                    <div dangerouslySetInnerHTML={{__html: content.replace(/\n/g, '<br />')}}/>
                </ModalBody>
            </Modal>
            {isClippingOpen && (
                <Modal isOpen={true} toggle={() => setIsClippingOpen(!isClippingOpen)} size="lg">
                    <ModalHeader toggle={() => setIsClippingOpen(!isClippingOpen)}>
                        <h2 dangerouslySetInnerHTML={{__html: title}}/>
                    </ModalHeader>
                    <ModalBody>
                        {clipping && <img alt="" src={`${CLIPPING_URL_LARGE}/${clipping}`}/>}
                    </ModalBody>
                </Modal>
            )}
        </>
    )
}

ArticleModal.propTypes = propTypes;

export default ArticleModal;

export {
    ArticleModalArticleProps
}
