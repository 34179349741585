import React from 'react';
import {FavoritesProvider} from "../../../context";
import {Dashboard} from "../../Templates";

const Favorites = () => {
    return (
        <FavoritesProvider>
            <Dashboard/>
        </FavoritesProvider>
    )
};

export default Favorites;
