import {Flex} from "../Atoms";
import React from "react";
import styled from 'styled-components';
import Ticket from "../Atoms/Icons/Ticket";
import {Button} from "reactstrap";

const StyledLock = styled(Flex)`
    position: relative;
    
    &:after {
        content: "";
        position: absolute;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, .7) 40%, rgba(255, 255, 255, 1) 80%);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`;

const StyledUnlock = styled(Flex)`
    position: absolute;
    bottom: 0;
    z-index: 10;
`;

const Lock = ({locked, children, ...rest}) => {
    if (!locked) {
        return <Flex {...rest}>
            {children}
        </Flex>;
    }

    return <Flex flexDirection="column" {...rest}>
        <StyledLock>
            {children}
            <StyledUnlock>
                {/*<Button className="mr-3">Odomknúť</Button>*/}
                {/*<Flex alignItems="center">*/}
                    <Button href="https://www.sita.sk/o-nas/#obchod" target="_blank" color="primary">Objednať</Button>
                {/*</Flex>*/}
            </StyledUnlock>
        </StyledLock>
    </Flex>
}

export default Lock;
