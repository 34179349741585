import {createContext, useState} from "react";
import {useQuery} from "@apollo/client";
import {GET_PROFILE, NOTES_GET_PROFILE} from "../queries";
import {ssoClient} from "../clients";

const UserContext = createContext({
    user: {
        token: null,
        meta: null,
        sso: {},
        notes: {}
    },
    setUser: () => {}
});

const UserProvider = ({children, value = {
    token: null
}}) => {
    const [user, setUser] = useState({
        token: null,
        meta: JSON.parse(atob(value.token.split('.')[1])),
        sso: {
            username: '',
            gender: 'm'
        },
        notes: {},
        ...value
    })

    const notes = useQuery(NOTES_GET_PROFILE, {
        context: {
            headers: {
                Authorization: value?.token
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: data => {
            setUser({
                ...user,
                notes: data.profile
            })
        }
    });

    const sso = useQuery(GET_PROFILE, {
        client: ssoClient,
        context: {
            headers: {
                Authorization: value?.token
            }
        },
        onCompleted: data => setUser({
            ...user,
            sso: data.user
        })
    });

    return <UserContext.Provider value={{user, setUser, sso, notes}}>
        {children}
    </UserContext.Provider>
};

export default UserContext;

export {
    UserProvider
}
