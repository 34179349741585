import * as React from "react";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {useEffect} from "react";

const Auth = () => {
    const history = useHistory();
    const location = useLocation();
    const {token} = useParams();

    useEffect(() => {
        localStorage.setItem('authorization', token);

        const url = new URLSearchParams(location.search);
        if (url.get('referrer')) {
            history.push('/dashboard')
            // props.history.push(`/new?query=${url.get('referrer')}`);
        } else {
            history.push('/dashboard')
        }

    }, []);

    return null;
}

export default Auth;
