import {Link, useHistory} from "react-router-dom";
import moment from "moment";
import {Tooltip} from "reactstrap";
import {Badge, Flex, Icon} from "../../Atoms";
import Favorites from "../../Atoms/Icons/Favorites";
import {colors} from "../../../config/theme";
import {ArticlesContext, UserContext} from "../../../context";
import {useContext, useState} from "react";
import {useQuery} from "@apollo/client";
import {GET_CATEGORIES} from "../../../queries";
import {useMediaQuery} from "react-responsive/src";
import {lg} from "../../../responsive";

const Filters = () => {
    const {user} = useContext(UserContext);
    const {filter: {filter, setFilter}} = useContext(ArticlesContext);

    const history = useHistory();

    const {data} = useQuery(GET_CATEGORIES, {
        context: {
            headers: {
                Authorization: user.token
            }
        }
    });

    return <>
        <Badge id="service-reset" tag={Link} to="/dashboard" color={colors.black}
                     onClick={() => setFilter({
                         query: '',
                         date: {
                             from: moment().subtract(1, 'd').format('YYYY-MM-DD'),
                             to: moment().format('YYYY-MM-DD'),
                         },
                         categories: []
                     })}>Aktuálne</Badge>
        <Badge tag={Link} to="/dashboard" color={colors.silver} className="m-2" onClick={() => setFilter({
            ...filter,
            categories: []
        })}>Všetko</Badge>
        {data && [4, 5, 6, 53, 2, 3].map(categoryId => {
            const category = data.categories.find(category => parseInt(category.id) === categoryId)

            return <Badge
                key={category.id}
                id={`category-${category.id}`}
                color={`#${category.color}`}
                onClick={() => {
                    const params = {
                        ...filter,
                        categories: [parseInt(category.id)]
                    };
                    setFilter(params);
                    const url = new URLSearchParams({
                        query: params.query,
                        categories: params.categories,
                        from: params.date.from,
                        to: params.date.to
                    })
                    history.push(`/dashboard?${url.toString()}`)
                }}
            >{category.description.replace(' servis', '')}</Badge>
        })}
        <Badge tag={Link} to="/favorites" color={colors.black}>
            <Icon tag={Favorites} size={10} color={colors.white} fill={colors.white} className="me-2"/>
            Obľúbené ({user.notes.favorites?.length})
        </Badge>
    </>
}

const ServiceFilter = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {filter: {filter}} = useContext(ArticlesContext);

    const isLg = useMediaQuery(lg);

    return <>
        {!isLg && (
            <>
                <Badge id="service-filter" color={colors.tradewind} >
                    {0 !== filter.categories.length ? 'Servisy' : 'Servisy'}
                </Badge>
                <Tooltip target="service-filter" placement="bottom" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} autohide={false}>
                    <Flex flexDirection="column">
                        <Filters/>
                    </Flex>
                </Tooltip>
            </>
        )}
        {isLg && <Filters/>}
    </>
}

export default ServiceFilter;
