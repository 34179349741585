import * as React from 'react';
import moment from 'moment';
import {Flex} from "../../Atoms";
import {Button} from "reactstrap";

const FastButton = ({ children, onClick }) => (
    <Button outline={true} color="primary" onClick={onClick} className="my-1">{children}</Button>
)

const FastNavigation = ({onChange}) => (
    <Flex flex={1} flexDirection="column">
        <FastButton onClick={() => onChange(moment(), moment())}>Dnes</FastButton>
        <FastButton onClick={() => onChange(moment().subtract(1, 'd'), moment().subtract(1, 'd'))}>Včera</FastButton>
        <FastButton onClick={() => onChange(moment().subtract(6, 'd'), moment())}>Posledných 7 dní</FastButton>
        <FastButton onClick={() => onChange(moment().subtract(1, 'M').add(1, 'd'), moment())}>Posledných 30 dní</FastButton>
        <FastButton onClick={() => onChange(moment().subtract(1, 'M').startOf('month'), moment().subtract(1, 'M').endOf('month'))}>Predchádzajúci mesiac</FastButton>
        <FastButton onClick={() => onChange(moment().startOf('y'), moment())}>Tento rok</FastButton>
        <FastButton onClick={() => onChange(moment().subtract(1, 'y').startOf('year'), moment().subtract(1, 'y').endOf('year'))}>Predchádzajúci rok</FastButton>
    </Flex>
)

export default FastNavigation;
