import React from 'react';
import {Input} from "../Atoms";
import {FormFeedback, FormGroup, Label} from "reactstrap";

const FormikInput = (
    {
        field: {...fields},
        form: {touched, errors, ...rest},
        ...props
    }) => (
    <FormGroup>
        {props.label && <Label for={props.id} className={"label-color"}>{props.label}</Label>}
        <Input {...props} field={fields} form={{touched, errors, ...rest}}/>
        {touched[fields.name] && errors[fields.name] ? <FormFeedback>{errors[fields.name]}</FormFeedback> : ''}
    </FormGroup>
);
export default FormikInput;
