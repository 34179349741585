import * as React from "react";
import {Flex} from "../Atoms";
import img from "../../assets/icons/service-domace-spravodajstvo.svg";

const Service = ({service, active}) => (
    <Flex className="mb-4">
        <Flex alignItems="start" className="px-4">
            <img src={img} alt=""/>
        </Flex>
        <Flex flex={1} flexDirection="column">
            <h4>{service.title}</h4>
            <p>{service.perex}</p>

            {active && service.content}
        </Flex>
    </Flex>
);

export default Service;
