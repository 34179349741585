import React, {useContext} from 'react';
import styled from 'styled-components';
import {EosIcon, Flex} from "../../Atoms";
import {colors} from "../../../config/theme";
import PhotoContext from "../../../context/PhotoContext";

const StyledToolbar = styled(Flex)`
    background-color: ${colors.concrete};
    border: 1px solid ${colors.mercury};
`

const StyledA = styled.a.attrs({className: 'px-2'})`
    color: ${props => props.active ? colors.tradewind : colors.black};
    border-right: 1px solid ${colors.silver};
    cursor: pointer;
    
    &:hover {
        color: ${colors.tradewind};
    }
`

const ArticleToolbar = ({companies, persons}) => {
    const {photos} = useContext(PhotoContext);

    return <StyledToolbar className="p-2" flexShrink={0} alignItems="center">
        <Flex>Prejdi na:</Flex>
        <Flex>
            <StyledA
                className="d-flex align-items-center"
                onClick={() => document.getElementById('article').scrollIntoView({behavior: 'smooth'})}
            >
                <EosIcon className="fs-5 me-2">vertical_align_top</EosIcon>
                Začiatok správy
            </StyledA>
        </Flex>
        <Flex>
            <StyledA
                className="d-flex align-items-center"
                onClick={() => document.getElementById('sita').scrollIntoView({behavior: 'smooth'})}
            >
                <EosIcon className="fs-5 me-2">library_books</EosIcon>
                Súvisiace správy
            </StyledA>
        </Flex>
        {0 !== photos.length && (
            <Flex>
                <StyledA
                    className="d-flex align-items-center"
                    onClick={() => document.getElementById('photo-search').scrollIntoView({behavior: 'smooth'})}
                >
                    <EosIcon className="fs-5 me-2">photo_library</EosIcon>
                    Súvisiace fotografie
                </StyledA>
            </Flex>
        )}
        {persons && (
            <Flex>
                <StyledA
                    className="d-flex align-items-center"
                    onClick={() => document.getElementById('person-search').scrollIntoView({behavior: 'smooth'})}
                >
                    <EosIcon className="fs-5 me-2">contacts</EosIcon>
                    Súvisiace osobnosti
                </StyledA>
            </Flex>
        )}
        {companies && (
            <Flex>
                <StyledA
                    className="d-flex align-items-center"
                    onClick={() => document.getElementById('company-search').scrollIntoView({behavior: 'smooth'})}
                >
                    <EosIcon className="fs-5 me-2">shop_two</EosIcon>
                    Súvisiace firmy
                </StyledA>
            </Flex>
        )}
    </StyledToolbar>
}

export default ArticleToolbar;
