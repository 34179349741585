//@flow

import * as React from 'react';
import {colors} from "../../config/theme";
import styled from 'styled-components';
import logoBlack from '../../assets/icons/logo-black.svg';
import logoWhite from '../../assets/icons/logo-white.svg';

const StyledA = styled.a`
    cursor: pointer;
`;

const ClientBrand = (props) => (
    <StyledA className="navbar-brand" href="https://www.sita.sk" target="_blank" {...props}>
        <img src={colors.black === props.color ? logoBlack : logoWhite} alt="logo" width={150}/>
    </StyledA>
)

export default ClientBrand;
