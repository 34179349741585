import React, {useContext, useState} from 'react';
import {NOTES_SEARCH} from "../../../queries";
import {useQuery} from "@apollo/client";
import {ArticlesCard} from "../../Organisms";
import {ArticlesContext, UserContext} from "../../../context";
import {ArticleModal} from "../../index";
import moment from "moment";

const RelatedSearch = ({keywords, persons, companies, from, to, onClick, onMore}) => {
    const {user} = useContext(UserContext);
    const {filter: {filter, setFilter}} = useContext(ArticlesContext);
    const [selected, setSelected] = useState(null);

    let query = `"${keywords.join('", "')}"`;

    if (0 !== persons.length) {
        query = `("${persons.join('", "')}")^3 ` + query;
    }

    if (0 !== companies.length) {
        query += `("${companies.join('", "')}")^3 ` + query;
    }

    const {data} = useQuery(NOTES_SEARCH, {
        context: {
            headers: {
                Authorization: user.token
            }
        },
        variables: {
            query: query,
            from: from,
            to: to,

            offset: 0,
            limit: 5,
        }
    });

    return (
        <>
            <ArticlesCard id="sita"
                          title="Súvisiace správy SITA"
                          articles={data?.articles.data.map(article => {
                              const info = [];
                              if (-1 !== article.title.indexOf(':')) {
                                  info.push(article.title.split(':')[0])
                              }
                              info.push(moment(article.date).format('DD. MM. YYYY HH:mm'));
                              info.push(article.categories[0]?.parent.description.replace(' servis', ''));

                              return {
                                  ...article,
                                  title: (-1 !== article.title.indexOf(':')) ? article.title.split(':')[1] : article.title,
                                  info: info.join(', ')
                              }
                          })}
                          onSelect={id => setSelected(data?.articles.data.find(article => article.id === id))}
                          withBadge={false}
                          onClick={() => {
                              setFilter({
                                  ...filter,
                                  query: query,
                                  date: {
                                      from: from,
                                      to: to
                                  }
                              })
                              onMore();
                          }}
            />
            {selected && (
                <ArticleModal
                    title={selected.title}
                    content={selected.content}
                    source={selected.source}
                    date={selected.date}
                    service={selected.categories[0]?.parent.description.replace(' servis', '')}
                    author={selected.authors.map(author => author.nick).join(', ')}
                    clipping={selected.clippings}
                    onClose={() => setSelected(null)}
                />
            )}
        </>
    )
};

export default RelatedSearch;
