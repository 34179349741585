import {createContext} from "react";
import moment from "moment";

const ArticlesContext = createContext({
    filter: {
        filter: {
            query: '',
            date: {
                from: moment(),
                to: moment()
            },
            categories: []
        },
        setFilter: () => {},
    },
    results: {}
});

export default ArticlesContext;
