import React from 'react';
import {Formik, Field} from "formik";
import {FormikInput} from "../Molecules";
import {Link, useHistory} from "react-router-dom";
import styled from 'styled-components';
import {colors} from "../../config/theme";
import {useMutation} from "@apollo/client";
import {LOGIN} from "../../queries";
import {ssoClient} from "../../clients";
import {Button, Form, FormFeedback, FormGroup} from "reactstrap";

const StyledLink = styled(Link)`
    color: ${colors.mercury};
`;

const LoginForm = () => {
    const history = useHistory();
    const [login] = useMutation(LOGIN, {
        client: ssoClient
    });

    return <Formik initialValues={{}} onSubmit={(values, actions) => {
        login({
            variables: {
                username: values.username.trim(),
                password: values.password.trim()
            }
        })
            .then(response => {
                localStorage.setItem('authorization', response.data.login)
                history.push('/dashboard')
            })
            .catch(error => {
                actions.setFieldError('form', 'Nesprávne prihlasovacie údaje')
                // console.log(error)
            })
    }}>
        {({handleSubmit, handleReset, errors}) => (
            <Form onSubmit={handleSubmit} onReset={handleReset}>
                <Field component={FormikInput} name="username" placeholder="Prihlasovacie meno"/>
                <Field component={FormikInput} name="password" type="password" placeholder="Heslo"/>
                {errors['form'] ? <FormFeedback className="d-block mb-3">{errors['form']}</FormFeedback> : ''}
                <FormGroup>
                    <Button color="secondary">Prihlásiť</Button>
                </FormGroup>
                <p>
                    <StyledLink to="/forgotten-password">Zabudnuté heslo?</StyledLink>
                </p>
            </Form>
        )}
    </Formik>
}

export default LoginForm;
