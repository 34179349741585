//@flow

import * as React from 'react';
import styled from 'styled-components';
import {Avatar, Flex} from "../../components/Atoms";
import {Search, Header} from "../../components/Organisms/Dashboard";
import {useState} from "react";
import {colors} from "../../config/theme";
import Add from "../../components/Atoms/Icons/Add";
import SearchIcon from "../../components/Atoms/Icons/Search";
import {useMediaQuery} from "react-responsive/src";
import {lg, md} from "../../responsive";
import {Link} from "react-router-dom";
import {Container, NavLink} from "reactstrap";
import {BackButton, RefetchArticles, ResultsCount, ServiceFilter} from "../../components/Molecules/Dashboard";

const StyledRoot = styled(Flex)`
    height: 100vh
`;

const StyledNavLink = styled(NavLink)`
`

const StyledToolbar = styled(Flex)`
    background: ${colors.concrete};
`;

const Main = ({selected, setSelected, children}) => {
    const [search, setSearch] = useState(false);

    const isLg = useMediaQuery(lg);
    const isMd = useMediaQuery(md);

    return (
        <StyledRoot flexDirection="column">
            <Header actions={
                <>
                    <SearchIcon stroke={search ? colors.tradewind : colors.white} size={20} className="mx-2" onClick={() => setSearch(!search)}/>
                    <Add fill={colors.white} size={20} className="mx-2" onClick={() => alert('add')}/>
                    <StyledNavLink tag={Link} to="/logout" className="mx-2 p-0">
                        <Avatar size={30}/>
                    </StyledNavLink>
                </>
            }/>
            {(isMd || (!selected && search)) && <Search/>}
            {(
                !isLg && selected ? (
                    <StyledToolbar className="border-bottom py-2">
                        <Container>
                            <Flex alignItem="center">
                                <BackButton onClick={() => setSelected(null)}/>
                            </Flex>
                        </Container>
                    </StyledToolbar>
                ) : (
                    <Container className="py-3 fw-bold">
                        <Flex justifyContent="space-between" alignItems="center">
                            <Flex md={4} justifyContent="space-between" alignItems="center">
                                <ResultsCount/>
                                <RefetchArticles/>
                            </Flex>
                            <Flex md={8} justifyContent="flex-end">
                                <ServiceFilter/>
                            </Flex>
                        </Flex>
                    </Container>
                )
            )}
            {children}
        </StyledRoot>
    )

}

export default Main;
