import React, {useState} from 'react';
import {ArticleDetail, ArticleList} from "../../components/Organisms";
import {Flex} from "../../components/Atoms";
import styled from "styled-components";
import {Container} from "reactstrap";
import {useMediaQuery} from "react-responsive/src";
import {lg} from "../../responsive";
import {Main} from ".";

const Scrollable = styled(Flex)`
    overflow: auto;
`;

const StyledContainer = styled(Container)`
    display: flex;
    flex: 1;
    overflow: hidden;
`;

const Dashboard = () => {
    const [selected, setSelected] = useState(null);

    const isLg = useMediaQuery(lg);

    return <Main selected={selected} setSelected={setSelected}>
        <StyledContainer>
            {(isLg || !selected) && <Scrollable lg={4} flexDirection="column" flex={1}>
                <ArticleList onSelect={setSelected} />
            </Scrollable>}
            {isLg && (
                <Flex lg={8} className="p-3">
                    {selected && (
                        <ArticleDetail
                            id={selected.id}
                            title={selected.title}
                            content={selected.content}
                            date={selected.date}
                            authors={[selected.createdBy]}
                            tags={selected.tags}
                            persons={selected.persons}
                            companies={selected.companies}
                            service={selected.categories[0]?.parent.description.replace(' servis', '')}
                            setSelected={setSelected}
                        />
                    )}
                </Flex>
            )}
            {!isLg && selected && (
                <ArticleDetail
                    id={selected.id}
                    title={selected.title}
                    content={selected.content}
                    date={selected.date}
                    authors={[selected.createdBy]}
                    tags={selected.tags}
                    persons={selected.persons}
                    companies={selected.companies}
                    service={selected.categories[0]?.parent.description.replace(' servis', '')}
                    setSelected={setSelected}
                />
            )}
        </StyledContainer>
    </Main>
}

export default Dashboard;
