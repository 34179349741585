import {gql} from '@apollo/client';

export default gql`query Articles(
    $query: String!, 
    $from: String!, 
    $to: String!, 
    $categories: [Int!],
    $offset: Int
    $limit: Int
) {
    articles(
        query: $query, 
        from: $from, 
        to: $to, 
        categories: $categories,
        offset: $offset,
        limit: $limit
    ) {
        data {
            id
            title
            subtitle
            authors {
                nick
            }
            locale
            priority {
                name
            }
            date
            content
            categories {
                name
                description
                slug
                color
                parent {
                    name
                    description
                    slug
                    color
                }
            }
            tags {
                name
                slug
            }      
            companies {
                id
                name
            }
            persons
            createdBy {
                id
                nick
            }
        }
        meta {
            offset
            limit
            count
        }

    }
}`
