
const colors = {
    lightningYellow: '#FFC524',
    froly: '#F26979',
    outrageousOrange: '#FF652E',
    yellowOrange: '#FBB03B',
    scooter: '#35B4CF',
    tradewind: '#6BBFB9',
    flamingo: '#F15A24',
    jungleGreen: '#27AE60',
    crimson: '#CF142B',
    citron: '#95C11F',
    tussock: '#BF9149',
    hippieBlue: '#4AA7A0',
    fireBush: '#E29928',
    roman: '#D65A68',
    goldTips: '#E8AF11',

    boulder: '#7C7C7C',
    mercury: '#E5E5E5',
    silver : '#BDBDBD',
    concrete: '#F2F2F2',

    white: 'white',
    black: 'black'
}

colors['gray'] = colors.boulder;

export {colors};
