import React, {useContext, useState} from 'react';
import {Em, Flex, Hr} from "../../Atoms";
import {UserContext} from "../../../context";
import {ShowMore} from "../../Molecules";
import {GET_COMPANY} from "../../../queries";
import {orsrClient} from "../../../clients";
import {useQuery} from "@apollo/client";
import moment from "moment";
import {Badge} from "reactstrap";
import classNames from "classnames";

const CompanyBadge = ({active, company, onClick}) => {
    const {user} = useContext(UserContext);

    const {data, loading} = useQuery(GET_COMPANY, {
        client: orsrClient,
        context: {
            headers: {
                Authorization: user.token
            }
        },
        variables: {
            // id: 123876,
            id: company.id,
        }
    });

    if (loading) {
        return null;
    }

    return <Badge className={classNames('ml-2', {
        'text-white': active,
        'text-primary': !active
    })}
                  onClick={onClick}
                  color={active ? 'primary' : 'default'}
    >{data.company.businessNames[0].name}</Badge>
}

const AccountingInformation = ({company}) => {
    const accountingInformation = company?.accountingInformation?.[0];

    const profits = {};

    const years = [2018, 2019, 2020];
    years.forEach(year => {
        let newestFinancialStatement = null;
        let newestReportTable = null;

        accountingInformation.financialStatements.forEach(financialStatement => {
            if (year == moment(financialStatement.endAt).format('YYYY')) {
                financialStatement.financialReports.forEach(financialReport => {
                    financialReport.financialReportTables.forEach(financialReportTable => {
                        if ('Výkaz ziskov a strát' === financialReportTable?.name) {
                            newestFinancialStatement = financialStatement;
                            newestReportTable = financialReportTable;
                        }
                    })
                })
            }
        })

        const table = {}
        newestReportTable?.financialReportTableItems.map(item => {
            table[item.position] = item.data;
        });

        profits[year] = {
            'income': table[1],
            'profit': 'undefined' === typeof table[75] ? table[121] : table[75]
        }
    })

    return <table>
        <tr>
            <th>&nbsp;</th>
            {years.map(year => <th>{year}</th>)}
        </tr>
        <tr>
            <th>Tržby</th>
            {years.map(year => <td>{parseFloat(profits[year].income).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} &euro;</td>)}
        </tr>
        <tr>
            <th>Zisk/Strata</th>
            {years.map(year => <td>{parseFloat(profits[year].profit).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} &euro;</td>)}
        </tr>
    </table>
}

const RelatedCompanies = ({companies}) => {
    const {user} = useContext(UserContext);
    const [active, setActive] = useState(companies?.[0]);

    const {data, loading} = useQuery(GET_COMPANY, {
        client: orsrClient,
        context: {
            headers: {
                Authorization: user.token
            }
        },
        variables: {
            // id: 123876,
            id: active.id,
        }
    });

    if (loading) {
        return 'Loading...'
    }

    if (!data?.company) {
        return null;
    }

    return (
        <Flex id="company-search" flexDirection="column" flex={1}>
            <Flex justifyContent="space-between" alignItems="center">
                <h2>Súvisiace firmy</h2>
                <Flex>
                    {companies.map(company =>
                        <CompanyBadge key={company.id} onClick={() => setActive(company)} company={company} active={active.id === company.id}/>
                    )}
                </Flex>
            </Flex>
            <Hr/>
            <div className="mt-2">
                <h3>{data.company?.businessNames[0].name}</h3>
                {(data.company?.forwarded?.id || data.company?.businessNames[0].endAt) && <h6 className="text-danger">Spoločnosť je neaktívna</h6>}
                <p>
                    <div>
                        <Em>Sídlo</Em>  {data.company?.registeredSeats[0].street}, {data.company?.registeredSeats[0].city}, {data.company?.registeredSeats[0].zip}
                    </div>
                    <div>
                        <Em>Vznik</Em>  {moment(data.company?.dateOfEntries?.[0].date).format('DD. MM. YYYY')}, Obchodná register Bratislava I, oddiel: {data.company?.section} vložka č {data.company?.insertNumber}
                    </div>
                    <div>
                        <Em>Právna forma</Em>  {data.company?.legalForms[0].title}
                    </div>
                    <div>
                        <Em>Štatutári</Em>  {
                            data.company?.managementBodies
                            .filter(managementBody => null === managementBody.endAt)
                            .filter(managementBody => null !== managementBody.surname)
                            .map((managementBody, index) => (
                                <>
                                    {0 !== index && ', '}
                                    <span className="font-weight-normal">{`${managementBody.name} ${managementBody.surname}`}</span>
                                    <span className="font-weight-light">{managementBody.type && ` (${managementBody.type})`}</span>
                                </>
                            ))
                        }
                    </div>
                    <div>
                        <Em>Spoločníci</Em>  {
                            data.company?.partners
                            .filter(partner => null === partner.endAt)
                            .filter(partner => (null !== partner.surname) || null !== partner.corporation)
                            .map((partner, index) => (
                                <>
                                    {0 !== index && ', '}
                                    {partner.surname && <span className="font-weight-normal">{`${partner.name} ${partner.surname}`}</span>}
                                    {partner.corporation && <span className="font-weight-normal">{partner.corporation}</span>}
                                    <span className="font-weight-light">{partner.type && ` (${partner.type})`}</span>
                                </>
                            ))
                        }
                    </div>
                </p>
                <p>
                    <div>
                        <Em>IČO</Em>  {data.company?.identificationNumbers[0].number}
                    </div>
                </p>
            </div>
            <Hr/>
                <AccountingInformation company={data.company}/>
            <Hr/>
            <a href={`https://orsr.sk/vypis.asp?ID=${data.company?.companyId.split('_')[0]}&SID=${data.company?.companyId.split('_')[1]}&P=0`}
               target="_blank"
               rel="noreferrer"
            >
                <ShowMore/>
            </a>
        </Flex>
    )
};

export default RelatedCompanies;
